import { GET_VEHICLES_REQUEST, CLEAR_VEHICLES_RESULT } from '../app/actionTypes';
import { TRANSLATIONS_SUCCESS } from '../translations/actionTypes';
import { translationParams } from '../translations/constants';


function localizeString(text, dealerCountry, country) {
  let newText = '';
  if (typeof text !== 'undefined') {
    newText = text.replace(/<Home_Country>/g, dealerCountry)
      .replace(/<Current_Country>/g, country)
      .replace(/<Home_Country_Code>/g, dealerCountry.toLowerCase());
  }
  return newText;
}

export const initialState = {
  countryMessage: {
    title: '',
    text: '',
    link: '',
    dealerCountry: '',
  }
};

const pageHeaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_VEHICLES_RESULT:
      return initialState;
    case GET_VEHICLES_REQUEST:
      return initialState;
    case TRANSLATIONS_SUCCESS: {
      if (action.pageName === translationParams.countries) {
        return {
          ...state,
          countryMessage: {
            ...state.countryMessage,
            title: localizeString(action.translations.title, action.translations[window.dealerCountry], action.translations[window.country]),
            text: localizeString(action.translations.text, action.translations[window.dealerCountry], action.translations[window.country]),
            link: localizeString(action.translations.link, window.dealerCountry, ''),
            dealerCountry: action.translations[window.dealerCountry],
          }
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default pageHeaderReducer;
