import routes from '../app/router/routes';
import { getVehicleName } from '../common/listingTypeUtils';

const extractTrackingValuesFromRoute = () => {
  const eventTrackingValuesByPath = Object.keys(routes).reduce((seed, key) => {
    const route = routes[key];
    return {
      ...seed,
      [route]: key.toLowerCase()
    };
  }, {});

  // Set the default root value
  eventTrackingValuesByPath['/'] = 'makemodel';

  return eventTrackingValuesByPath;
};

export const eventTrackingValuesByPath = extractTrackingValuesFromRoute();

const windowRef = typeof window === 'undefined' ? {} : window;

windowRef.ut = (typeof window === 'undefined' || typeof window.ut === 'undefined') ? { push() {} } : window.ut;
windowRef.dataLayer = (typeof window === 'undefined' || typeof window.dataLayer === 'undefined') ? { push() {} } : window.dataLayer;

export const pageTrack = () => {
  if (windowRef.trackingConfiguration === undefined) {
    return;
  }

  windowRef.ut.push(['pagename', {
    country: windowRef.trackingConfiguration.country,
    market: windowRef.trackingConfiguration.market,
    category: windowRef.trackingConfiguration.category,
    pageid: windowRef.trackingConfiguration.pageid,
    layer: '',
    attribute: getVehicleName(window.listingType),
    group: windowRef.trackingConfiguration.group,
    environment: windowRef.trackingConfiguration.environment,
    language: windowRef.trackingConfiguration.language
  }]);

  windowRef.ut.push(['gtm', 'pageview']);

  windowRef.ut.push([
    'gtm',
    'set',
    {
      dealer_customer_id: windowRef.trackingConfiguration.dealer_customer_id,
      common_loginState: windowRef.trackingConfiguration.common_loginState
    },
  ]);
};

export function trackGA4PageView({ contentGroup, contentID, pageTitle }) {
  if (!windowRef.ga4) {
    console.log('GA4 tracking library not found!');
    return;
  }

  windowRef.ga4.trackGA4PageViewEvent({
    content_group: contentGroup,
    content_id: contentID,
    page_title: pageTitle,
  });
}

export function eventTrack(eventActionName, label) {
  windowRef.dataLayer.push({
    event: 'event_trigger',
    eventTimeout: 300,
    event_category: windowRef.trackingConfiguration.category,
    event_action: eventActionName,
    event_label: label,
    event_non_interaction: 'false',
  });
}
export function trackEventGA4(eventName, eventType, detailsPlacement) {
  if (!windowRef.ga4) {
    console.log('GA4 tracking library not found!');
    return;
  }

  const detailsPlacementMap = {
    makemodel: 'make_model',
    hsntsn: 'car_code',
  };

  const mappedDetailsPlacement = detailsPlacementMap[detailsPlacement] || detailsPlacement;

  windowRef.ga4.trackGA4Event({
    name: eventName,
    type: eventType,
    details: {
      placement: mappedDetailsPlacement,
    }
});
}

export function routeEventTrack(eventActionName, eventType, route) {
  // On localhost events are being triggered twice on the GA Debug View but this works fine in prod.
  // More info: https://support.google.com/analytics/thread/98071675/custom-event-is-fired-twice-on-a-page?hl=en
  const tabParam = eventTrackingValuesByPath[route] ? eventTrackingValuesByPath[route] : 'unknow';
  eventTrack(eventActionName, `${eventType}_${tabParam}`);
}
