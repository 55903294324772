import * as defaultValues from './defaultValues';

export const kwToHpRatio = 1.35962162;
// eslint-disable-next-line
export const regexEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
// eslint-disable-next-line
export const regexUrl = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
export const regexTags = /<\/?\s?.*\s?\/?>/;

export const regexValidateLicensePlateItaly = /^[ABCDEFGHJKLMNPRSTVWXYZ]{2}[0-9]{3}[ABCDEFGHJKLMNPRSTVWXYZ]{2}/;
export const regexValidateLicensePlateNederlands = /^[0-9A-Z]{6,8}$/;

export const itLicensePlateMaxLength = 7;
export const nlLicensePlateMaxLength = 8;

export const index = {
  vehicleOfferType: 0,
  make: 1,
  firstRegistration: 2,
  model: 3,
  fuel: 4,
  power: 5,
  equipmentLine: 6,
  color: 7,
  result: 8
};
export const fieldNames = {
  vehicleOfferType: 'vehicleOfferType',
  make: 'make',
  firstRegistration: 'firstRegistration',
  model: 'model',
  fuel: 'fuel',
  equipmentLine: 'equipmentLine',
  color: 'color',
  power: 'power',
  result: 'result',
  licensePlate: 'licensePlate'
};
export const fields = [{ name: fieldNames.vehicleOfferType, value: defaultValues.selectNumber },
  { name: fieldNames.make, value: defaultValues.selectNumber },
  { name: fieldNames.firstRegistration, value: defaultValues.firstReg },
  { name: fieldNames.model, value: defaultValues.selectStringUnderscoreSeparated },
  { name: fieldNames.fuel, value: defaultValues.selectStringDisabled },
  { name: fieldNames.power, value: defaultValues.selectNumberHidden },
  { name: fieldNames.equipmentLine, value: defaultValues.selectStringHidden },
  { name: fieldNames.color, value: defaultValues.selectStringDisabled },
  { name: fieldNames.result, value: [] }];
export const taxonomyStatus = {
  valid: 'TAXONOMY_VALID',
  error: 'TAXONOMY_ERROR',
  skip: 'TAXONOMY_SKIP',
  natValid: 'NAT_VALID',
  natNotFound: 'NAT_NOTFOUND',
};

export const vehicleType = {
  car: 'C',
  bike: 'B',
};

export const vehicleName = {
  car: 'car',
  bike: 'bike'
};

export const listingType = {
  carDealer: 'carDealer',
  bikePrivate: 'bikePrivate',
  carSourcing: 'carSourcing',
  carPrivate: 'carPrivate',
};

export const userType = {
  private: 'P',
  basic: 'B',
  dealer: 'D',
};

export const locale = {
  deAT: 'de-AT',
  deDE: 'de-DE',
  esES: 'es-ES',
  frFR: 'fr-FR',
  frBE: 'fr-BE',
  nlBE: 'nl-BE',
  frLU: 'fr-LU',
  itIT: 'it-IT',
  nlNL: 'nl-NL',
};

export const entryPointTypes = {
  natcode: 'natcode',
  vin: 'vin',
  licensePlate: 'licenseplate',
  makemodel: 'makemodel',
  bikeprivate: 'bikeprivate',
  hsntsn: 'hsntsn',
  home: 'home',
  carSourcing: 'carSourcing',
  carPrivate: 'carprivate',
};

export const lastTabKey = 'as24_listing_creation_lastTab';

export const vehicleOfferTypeDefaultValue = 'U';

export const datSupportedMakesLightBoxId = 'dat-supported-makes-box';

export const imageClasses = {
  circleCheckIcon: 'circleCheck',
  pictureIcon: 'pictureIcon',
  bikeIcon: 'bikeIcon',
  appointmentsIcon: 'appointmentsIcon',
  moneyIcon: 'moneyIcon'
};

export const toggles = {
};
