const eventify = (arr, callback) => {
    // eslint-disable-next-line no-param-reassign
    arr.push = (e) => {
        Array.prototype.push.call(arr, e);
        callback(e);
    };
};

export const listenToElementPush = (array, condition, callback) => {
    if (!(condition instanceof Function) || !(callback instanceof Function)) return;

    if (!array) {
        // eslint-disable-next-line no-param-reassign
        array = [];
    }

    if (Array.isArray(array)) {
        eventify(array, (element) => {
            if (condition(element)) {
                callback(element);
            }
        });
    }
};
