import { connect } from 'react-redux';
import { changeAndUpdateLowerFields, showColor } from '../actions';
import DropDown from '../../../common/components/dropdown';
import { fieldNames } from '../../../common/hsnTsnConstants';

function mapStateToProps(state) {
  return {
    label: state.translations.hsntsn.data.equipmentLineLabel,
    name: fieldNames.equipmentLine,
    componentState: state.hsnTsnData.equipmentLine,
    defaultText: state.translations.hsntsn.data.pleaseChoose,
    options: state.hsnTsnData.equipmentLine.options
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onChange: (e) => {
      dispatch(changeAndUpdateLowerFields(e.target.value, fieldNames.equipmentLine));
      if (e.target.value !== '0') dispatch(showColor());
    }
  };
}
const EquipmentLine = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDown);

export default EquipmentLine;
