import { connect } from 'react-redux';
import VehicleList from './vehicleListComponent';

function mapStateToProps(state) {
  const pathname = state.routing.locationBeforeTransitions
    ? state.routing.locationBeforeTransitions.pathname
    : '/makemodel';
  return {
    vehicles: state.vehicleList.vehicles,
    translations: state.translations,
    route: pathname,
    colorParameter: state.vehicleList.color,
    vehicleOfferTypeParameter: state.vehicleList.vehicleOfferType,
    manualLink: state.vehicleList.manualLink,
    pageName: state.configuration.translationsPageName.vehicle.pageName,
    listingFormUrl: state.configuration.urls.formUrl,
    manualUrl: state.configuration.urls.manualUrl
  };
}

export default connect(mapStateToProps)(VehicleList);
