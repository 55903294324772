import { connect } from 'react-redux';
import {
  changeFirstRegistrationAndUpdateLowerFields, getNextData, clearResults, fillManualLink, buttonReset
} from '../actions';
import * as defaultValues from '../../../common/defaultValues';
import DateSelect from '../../../common/components/monthYear';
import { fieldNames } from '../../../common/constants';

function setYears(max, min) {
  const currentYear = new Date().getFullYear();
  const firstRegYears = [];
  for (let i = max; i > -min; i--) {
    firstRegYears.push(currentYear + i);
  }
  return firstRegYears;
}

function mapStateToProps(state) {
  const pageName = state.configuration.translationsPageName.makemodel.pageName;
  return {
    label: state.makeModelData.buildRange ? state.translations[pageName].data.buildRange : state.translations[pageName].data.firstRegistration,
    componentState: state.makeModelData.firstRegistration,
    years: setYears(defaultValues.maxYear, defaultValues.minYear),
    translations: {
      month: state.translations[pageName].data.month,
      year: state.translations[pageName].data.year,
      monthErrorMessage: state.translations[pageName].data.monthErrorMessage,
      yearErrorMessage: state.translations[pageName].data.yearErrorMessage,
      monthYearErrorMessage: state.translations[pageName].data.monthYearErrorMessage
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    monthChange: (e) => {
      dispatch(changeFirstRegistrationAndUpdateLowerFields('month', 'year', e.target.value, fieldNames.firstRegistration));
      dispatch(getNextData(fieldNames.firstRegistration));
      dispatch(fillManualLink());
      dispatch(clearResults());
      dispatch(buttonReset());
    },
    yearChange: (e) => {
      dispatch(changeFirstRegistrationAndUpdateLowerFields('year', 'month', e.target.value, fieldNames.firstRegistration));
      dispatch(getNextData(fieldNames.firstRegistration));
      dispatch(fillManualLink());
      dispatch(clearResults());
      dispatch(buttonReset());
    }
  };
}

const FirstRegistration = connect(
  mapStateToProps,
  mapDispatchToProps
)(DateSelect);

export default FirstRegistration;
