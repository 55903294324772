import React from 'react';
import { PropTypes } from 'prop-types';
import NatCodeInput from './containers/natCode';
import FirstReg from './containers/firstRegistration';
import BuildDate from './containers/buildDate';

const NatCode = ({ showBuildDate, showCarPrivateBgTabs }) => {
  const formDivClass = `sc-clearfix sc-block-center lc-padding-bottom-xxl ${(showCarPrivateBgTabs
    ? 'lc-padding-horizontal-xxxl sc-grid-col-12'
    : 'sc-padding-horizontal-l sc-grid-col-6 sc-grid-col-s-12')}`;
  return (
    <div className="sc-grid-row">
      <div className="sc-grid-col-3 sc-hidden-s" />
      <div className={formDivClass}>
        <NatCodeInput />
        {showBuildDate ? <BuildDate /> : null}
        <FirstReg />
      </div>
    </div>
  );
};

NatCode.propTypes = {
  showBuildDate: PropTypes.bool,
  showCarPrivateBgTabs: PropTypes.bool
};

export default NatCode;
