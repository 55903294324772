import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import vin from '../modules/vin/vinReducers';
import pageHeader from '../pageheader/pageHeaderReducer';
import vehicleList from '../vehicle/vehicleListReducer';
import translations from '../translations/reducer';
import makeModelData from '../modules/makemodel/reducer';
import hsnTsnData from '../modules/hsntsn/reducer';
import natCode from '../modules/natcode/reducer';
import bikePrivate from '../modules/bikeprivate/reducer';
import licensePlate from '../modules/licensePlate/reducer';
import notification from '../notifications/reducer';

// app reducers

const listingEntryPoint = configuration => combineReducers({
  vin,
  pageHeader,
  vehicleList,
  makeModelData,
  bikePrivate,
  hsnTsnData,
  natCode,
  translations,
  licensePlate,
  notification,
  configuration: (state = configuration) => state,
  routing: routerReducer,
});

const rootReducer = configuration => (state, action) => {
  let newState = state;
  if (action.type === 'CLEAR_ALL_DATA') {
    newState = {
      ...state,
      pageHeader: undefined,
      vin: undefined,
      vehicleList: undefined,
      makeModelData: undefined,
      hsnTsnData: undefined,
      natCode: undefined,
      bikePrivate: undefined,
      licensePlate: undefined
    };
  }
  return listingEntryPoint(configuration)(newState, action);
};

export default rootReducer;
