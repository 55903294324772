import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Button from '../../../common/components/button';
import {
 changeIsLoading, clearResults, setLicensePlateToRed, touchLicensePlateFields
} from '../action';
import { fetchVehicles } from '../../services/licensePlateApi';
import { getVehiclesSuccess } from '../../common/actions';
import { routeEventTrack } from '../../../tracking/tracking';
import { globalTechnicalErrorNotification } from '../../../notifications/helpers';

const ButtonLicensePlate = () => {
  const {
    translations: { noVehicleErrorMessage, submit },
    licensePlate,
    route,
    licensePlateUrl,
    listingFormUrl,
    isRecaptchaVerified
  } = useSelector(state => ({
    translations: state.translations.licensePlate.data,
    licensePlate: state.licensePlate,
    route: state.routing.locationBeforeTransitions.pathname,
    licensePlateUrl: state.configuration.urls.licensePlateUrl + state.licensePlate.licensePlate.value,
    listingFormUrl: state.configuration.urls.formUrl,
    isRecaptchaVerified: state.licensePlate.isRecaptchaVerified
  }), shallowEqual);

  const dispatch = useDispatch();

  const showError = useCallback((message) => {
    dispatch(changeIsLoading());
    globalTechnicalErrorNotification(dispatch, noVehicleErrorMessage, message);
    dispatch(setLicensePlateToRed());
  }, [dispatch, noVehicleErrorMessage]);

  // I didn't change to async at the moment because I was not able to run to verify if UX keeps the same
  // Idiomatic to hook as prevents unnecessary rerenders
  const clickHandler = useCallback((e) => {
    e.preventDefault();
    new Promise(res => res(dispatch(touchLicensePlateFields)))
      .then(() => dispatch(clearResults()))
      .then(() => dispatch(changeIsLoading()))
      .then(() => fetchVehicles(licensePlateUrl))
      .then((vehicles) => {
        if (vehicles.length < 1) {
          showError('');
        } else if (vehicles.length === 1) {
          routeEventTrack('myarea_listing_creation', 'listing', route);
          window.location = `${listingFormUrl}${vehicles[0].url}`;
        } else {
          dispatch(changeIsLoading());
          dispatch(getVehiclesSuccess(vehicles));
        }
      })
      .catch(error => showError(error.toString()));
  }, [route, licensePlateUrl, showError]);

  return (
    <Button
      id="submit-license-plate"
      className="license-plate-button sc-btn-bob sc-btn-block sc-grid-row sc-grid-col-7 sc-grid-col-m-11 max-width-s lc-block-center sc-margin-top-s"
      text={submit}
      onClick={clickHandler}
      disabled={!licensePlate.licensePlate.isValid || licensePlate.isLoading || licensePlate.licensePlate.isHighlighted || !isRecaptchaVerified}
    />
  );
};

export default ButtonLicensePlate;
