import * as actions from '../hsntsn/actions';

const getColorData = () => fetch('/listing-creation-entry-point/lookups/colors', { credentials: 'include' })
  .then(response => response.json());

export const fetchColorsAsync = (getColors = getColorData) => (dispatch) => {
  dispatch(actions.fetchColors());
  return getColors()
    .then((json) => {
      dispatch(actions.colorsSuccess(json.colors));
    })
    .catch((error) => {
      dispatch(actions.colorsFailure(error));
    });
};

const getVehicleOfferTypeData = () => fetch('/listing-creation-entry-point/lookups/vehicle-offer-types', { credentials: 'include' })
  .then(response => response.json());

export const fetchVehicleOfferTypesAsync = (getVehicleOfferTypes = getVehicleOfferTypeData) => (dispatch) => {
  dispatch(actions.fetchVehicleOfferTypes());
  return getVehicleOfferTypes()
    .then((json) => {
      dispatch(actions.vehicleOfferTypesSuccess(json.vehicleOfferTypes));
    })
    .catch((error) => {
      dispatch(actions.vehicleOfferTypesFailure(error));
    });
};
