export const GET_VEHICLES_FAILURE = 'GET_VEHICLES_FAILURE';
export const GET_VEHICLES_REQUEST = 'GET_VEHICLES_REQUEST';
export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS';
export const CHANGE_FIELD_VALUE = 'CHANGE_FIELD_VALUE';
export const GET_VEHICLES_VIN_ERROR = 'GET_VEHICLES_VIN_ERROR';
export const CLEAR_VEHICLES_RESULT = 'CLEAR_VEHICLES_RESULT';
export const UPDATE_VIN = 'UPDATE_VIN';
export const SET_COLOR_PARAMETER = 'SET_COLOR_PARAMETER';
export const FILL_MANUAL_LINK = 'FILL_MANUAL_LINK';
export const VEHICLE_OFFER_TYPES_REQUEST_VIN = 'VEHICLE_OFFER_TYPES_REQUEST_VIN';
export const VEHICLE_OFFER_TYPES_SUCCESS_VIN = 'VEHICLE_OFFER_TYPES_SUCCESS_VIN';
export const VEHICLE_OFFER_TYPES_FAILURE_VIN = 'VEHICLE_OFFER_TYPES_FAILURE_VIN';
export const CHANGE_VEHICLE_OFFER_TYPE_VIN = 'CHANGE_VEHICLE_OFFER_TYPE_VIN';
export const SET_VEHICLE_OFFER_TYPE_PARAMETER = 'SET_VEHICLE_OFFER_TYPE_PARAMETER';
