import {
  changeRedirectButton,
  fillManualLink,
  getNextData,
  showColor,
  showEquipmentLine,
  taxonomyFailure,
  taxonomyResultSuccess,
  taxonomySuccess
} from '../makemodel/actions';
import { fieldNames, taxonomyStatus } from '../../common/constants';
import * as paramHelper from '../../helpers/paramsHelper';
import { globalTechnicalErrorNotification } from '../../notifications/helpers';

const getData = params => fetch(window.trackingConfiguration.environment === 'live'
  ? 'https://listing-creation-entry-point.a.autoscout24.com/listing-creation-entry-point/taxonomy'
  : '/listing-creation-entry-point/taxonomy',
{
  credentials: 'same-origin',
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'csrf-token': window.csrfToken
  },
  body: JSON.stringify(params)
})
  .then(response => response.json());

const getNatData = (natCode, firstReg) => fetch('/listing-creation-entry-point/natcode',
  {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'csrf-token': window.csrfToken
    },
    body: JSON.stringify({ natCode, firstReg })
  })
  .then(response => response.json());

const createFilterParams = (state, idxNext) => {
  return {
    makeId: paramHelper.emptyIntToNull(state.makeModelData.make.value),
    month: paramHelper.emptyStringToNull(state.makeModelData.firstRegistration.month),
    year: paramHelper.emptyStringToNull(state.makeModelData.firstRegistration.year),
    hsn: paramHelper.emptyStringToNull(state.makeModelData.hsn),
    tsn: paramHelper.emptyStringToNull(state.makeModelData.tsn),
    isBuildRange: state.makeModelData.buildRange,
    modelId: paramHelper.emptyIntArrayToNull(state.makeModelData.model.value, 0),
    bodyType: paramHelper.emptyIntArrayToNull(state.makeModelData.model.value, 1),
    doors: paramHelper.emptyIntArrayToNull(state.makeModelData.model.value, 2),
    fuelType: paramHelper.emptyStringToNull(state.makeModelData.fuel.value),
    powerKw: paramHelper.emptyIntToNull(state.makeModelData.power.value),
    equipmentLine: paramHelper.emptyString(state.makeModelData.equipmentLine.value),
    nextField: idxNext,
    culture: window.currentCulture,
  };
};

export const getNextTaxonomyData = (field, state, getTaxonomyData = getData, filterParams = createFilterParams) => (dispatch) => {
  return getTaxonomyData(filterParams(state, field))
    .then((json) => {
      switch (json.status) {
        case taxonomyStatus.valid:
          if (field === fieldNames.result) {
            dispatch(taxonomyResultSuccess(json.data, state.makeModelData.buildRange));
            dispatch(fillManualLink());
          } else {
            dispatch(taxonomySuccess(json.data, field));
            if (json.data.length === 1) {
              dispatch(getNextData(field));
              if (field === fieldNames.equipmentLine) {
                dispatch(showColor());
              }
            }
            if (field === fieldNames.power) {
              dispatch(showEquipmentLine());
            }
          }
          dispatch(changeRedirectButton(true));
          break;
        case taxonomyStatus.skip:
          if (field === fieldNames.power) {
            dispatch(showColor());
            dispatch(taxonomySuccess(json.data, fieldNames.color));
          } else {
            dispatch(taxonomySuccess(json.data, field));
          }
          dispatch(changeRedirectButton(false));
          break;
        case taxonomyStatus.error:
          dispatch(taxonomyFailure(json.error));
          break;
        default:
          dispatch(taxonomySuccess(json.data, field));
          dispatch(changeRedirectButton(true));
          break;
      }
    })
    .catch((error) => {
      dispatch(taxonomyFailure(error));
    });
};

export const getNatResult = () => {
  return (dispatch, getState) => {
    const state = getState();
    return getNatData(state.natCode.natCode.value, state.natCode.firstRegistration.year + state.natCode.firstRegistration.month)
      .then((json) => {
        switch (json.status) {
          case taxonomyStatus.natValid:
            dispatch(taxonomyResultSuccess(json.data));
            break;
          case taxonomyStatus.natNotFound:
            globalTechnicalErrorNotification(dispatch, json.data.errorMessage, json.data.errorLinkText);
            break;
          default:
            globalTechnicalErrorNotification(dispatch, json.data.errorMessage, json.data.errorLinkText);
            break;
        }
      })
      .catch((error) => {
        globalTechnicalErrorNotification(dispatch, state.translations.natCode.data.noNatMessage, error.toString());
      });
  };
};
