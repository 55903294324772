import { connect } from 'react-redux';
import { changeFirstRegistration, getData, clearResults } from '../actions';
import * as defaultValues from '../../../common/defaultValues';
import DateSelect from '../../../common/components/monthYear';

function setYears(max, min) {
  const currentYear = new Date().getFullYear();
  const firstRegYears = [];
  for (let i = max; i > -min; i--) {
    firstRegYears.push(currentYear + i);
  }
  return firstRegYears;
}

function mapStateToProps(state) {
  return {
    label: state.translations.natCode.data.firstRegistration,
    componentState: state.natCode.firstRegistration,
    years: setYears(defaultValues.maxYear, defaultValues.minYear),
    translations: {
      month: state.translations.natCode.data.month,
      year: state.translations.natCode.data.year,
      monthErrorMessage: state.translations.natCode.data.monthErrorMessage,
      yearErrorMessage: state.translations.natCode.data.yearErrorMessage,
      monthYearErrorMessage: state.translations.natCode.data.monthYearErrorMessage
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    monthChange: (e) => {
      dispatch(changeFirstRegistration('month', 'year', e.target.value));
      dispatch(getData());
      dispatch(clearResults());
    },
    yearChange: (e) => {
      dispatch(changeFirstRegistration('year', 'month', e.target.value));
      dispatch(getData());
      dispatch(clearResults());
    }
  };
}

const FirstRegistration = connect(
  mapStateToProps,
  mapDispatchToProps
)(DateSelect);

export default FirstRegistration;
