import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { notificationTarget } from './constants';

const Notification = ({ notifications }) => (
    <div>
        <div className="sc-content-container" id={notificationTarget} />
        <div className="sc-notification-container">
            {notifications.map(e => e())}
        </div>
    </div>
);

Notification.propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.func)
};

Notification.defaultProps = {
    notifications: []
};

function mapStateToProps(state) {
    return {
        notifications: state.notification.notifications
    };
}

const NotificationBox = connect(mapStateToProps)(Notification);

export default NotificationBox;
