import {
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_REQUEST,
  GET_VEHICLES_FAILURE,
  GET_VEHICLES_VIN_ERROR,
  UPDATE_VIN,
  VEHICLE_OFFER_TYPES_REQUEST_VIN,
  VEHICLE_OFFER_TYPES_SUCCESS_VIN,
  VEHICLE_OFFER_TYPES_FAILURE_VIN,
  CHANGE_VEHICLE_OFFER_TYPE_VIN
} from '../../app/actionTypes';
import { vehicleOfferTypeDefaultValue } from '../../common/constants';

export const initialState = {
  vinData: {
    number: '',
    isDisabled: true,
    vinError: ''
  },
  errorOccurred: false,
  success: false,
  isLoading: false,
  vehicleOfferType: {
    value: vehicleOfferTypeDefaultValue,
    isValid: true,
    isDisabled: false,
  },
  isFetchingVehicleOfferTypes: false,
  vehicleOfferTypes: []
};

const vinReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VEHICLES_REQUEST:
      return { ...state, isLoading: true, vinData: { ...state.vinData, vinError: '' } };
    case GET_VEHICLES_SUCCESS:
      return { ...state, success: true, isLoading: false };
    case GET_VEHICLES_FAILURE:
      return {
        ...state, errorOccurred: true, isLoading: false, vinData: { ...state.vinData, isDisabled: true }
      };
    case GET_VEHICLES_VIN_ERROR:
      return {
        ...state,
        errorOccurred: true,
        isLoading: false,
        vinData: { ...state.vinData, vinError: action.vinError, isDisabled: true }
      };
    case UPDATE_VIN:
      return {
        ...state,
        errorOccurred: false,
        vinData: { ...state.vinData, number: action.vin, isDisabled: action.vin.length !== 17 }
      };
    case VEHICLE_OFFER_TYPES_REQUEST_VIN:
      return { ...state, isFetchingVehicleOfferTypes: true };
    case VEHICLE_OFFER_TYPES_SUCCESS_VIN:
      return {
        ...state,
        vehicleOfferTypes: action.vehicleOfferTypes,
        isFetchingVehicleOfferTypes: false
      };
    case VEHICLE_OFFER_TYPES_FAILURE_VIN:
      return state;
    case CHANGE_VEHICLE_OFFER_TYPE_VIN:
      return {
        ...state,
        vehicleOfferType: {
          ...state.vehicleOfferType,
          value: action.value
        }
      };
    default:
      return state;
  }
};

export default vinReducer;
