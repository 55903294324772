import {
 FILL_MANUAL_LINK, GET_VEHICLES_SUCCESS
} from '../../app/actionTypes';
import { getCustomUrl } from '../../helpers/paramsHelper';

export const dispatchManualLink = (stateElement) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: FILL_MANUAL_LINK,
      url: getCustomUrl(state[stateElement], state.configuration.urls.formUrl)
    });
  };
};

export const getVehiclesSuccess = (vehicles) => {
  return {
    type: GET_VEHICLES_SUCCESS,
    vehicles,
  };
};
