import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

const CountryNotification = ({ message }) => {
  return (
    <div id="country-info" className="lc-pageHeader-info lc-padding-l">
      <div className="sc-font-m sc-font-bold lc-padding-horizontal-l">{message.title}</div>
      <div className="lc-padding-horizontal-l">
        {message.text}
        <a href={message.link}>{message.dealerCountry}</a>
      </div>
    </div>
  );
};

CountryNotification.propTypes = {
  message: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    dealerCountry: PropTypes.string,
    link: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    message: state.pageHeader.countryMessage,
  };
}

export default connect(mapStateToProps)(CountryNotification);
