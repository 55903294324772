import * as actionTypes from './actionTypes';
import { dispatchManualLink } from '../common/actions';

export const changeFieldValue = (name, value) => {
  return {
    type: actionTypes.CHANGE_FIELD_VALUE,
    name,
    value
  };
};

export const touchAllFields = dispatch => new Promise((resolve) => {
  resolve(
      dispatch({
        type: actionTypes.TOUCH_ALL_FIELDS,
      })
    );
  });

export const updateModelOptions = (options) => {
  return {
    type: actionTypes.UPDATE_MODEL_OPTIONS,
    options
  };
};

export const clearForm = () => {
  return {
    type: actionTypes.CLEAR_VEHICLES_RESULT,
  };
};

export const fillManualLink = () => dispatchManualLink('bikePrivate');
