import React from 'react';

export const topMakeModel = (makeModelData) => {
  const displayTopMakes = makeModelData.topMakes !== undefined && makeModelData.topMakes.length > 0;
  const topMakes = displayTopMakes ? makeModelData.topMakes.map((make) => {
      return <option value={make.id} key={make.id} className="lc-padding-left-l">{make.label}</option>;
    })
    : '';
  const optGroup = displayTopMakes ? <optgroup className="lc-padding-left-l" label="_____________" /> : '';

  return (
<>
  {topMakes}
  {optGroup}
</>
);
};
