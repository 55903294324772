import React from 'react';
import { PropTypes } from 'prop-types';
import { featureEnabled } from '../helpers/features';
import { routeEventTrack, eventTrackingValuesByPath, trackEventGA4 } from '../tracking/tracking';

function displayHeadlineAttributes(vehicle) {
  const attributes = [];

  if (vehicle.make) attributes.push(vehicle.make);
  if (vehicle.model) attributes.push(vehicle.model);
  if (vehicle.version) attributes.push(vehicle.version);
  if (vehicle.tsn) attributes.push(`TSN ${vehicle.tsn}`);

  return attributes.join(' ');
}

function displayAttributes(vehicle, translations, pageName) {
  const attributes = [];

  if (vehicle.bodyType) {
    attributes.push(vehicle.bodyType);
  }
  if (vehicle.doors) {
    attributes.push(`${vehicle.doors} ${translations[pageName].data.doors}`);
  }
  if (vehicle.fuelType) {
    attributes.push(vehicle.fuelType);
  }
  if (vehicle.powerKw && vehicle.powerPs) {
    attributes.push(`${vehicle.powerKw} ${translations[pageName].data.kilowatt} (${vehicle.powerPs} ${translations[pageName].data.horsepower})`);
  }
  if (vehicle.gearType) {
    attributes.push(vehicle.gearType);
  }
  if (vehicle.seats) {
    attributes.push(`${vehicle.seats} ${translations[pageName].data.seats}`);
  }
  return attributes.join(', ');
}

function goToListingForm(vehicle, route, colorParameter, vehicleOfferTypeParameter, listingFormUrl) {
  const trackingParameter = eventTrackingValuesByPath[route];
  const trackingParam = `&lct=${trackingParameter}`;
  const colorParam = colorParameter > 0 ? `&color=${colorParameter}` : '';
  const vehicleOfferTypeParam = vehicleOfferTypeParameter !== undefined ? `&vehicleOfferTypeId=${vehicleOfferTypeParameter}` : '';
  if (trackingParameter === 'vin' && featureEnabled('datVinServiceTemporary')) {
    if (vehicle.equipmentTextOemStandard !== null && vehicle.equipmentTextOemStandard !== undefined) {
      localStorage.setItem('equipmentTextOemStandard', JSON.stringify(vehicle.equipmentTextOemStandard));
    }
    if (vehicle.equipmentTextOemSpecial !== null && vehicle.equipmentTextOemSpecial !== undefined) {
      localStorage.setItem('equipmentTextOemSpecial', JSON.stringify(vehicle.equipmentTextOemSpecial));
    }
  }

  routeEventTrack('myarea_listing_creation', 'listing', route);
  trackEventGA4('listing_creation_select', 'action', eventTrackingValuesByPath[route]);

  window.location = listingFormUrl.concat(vehicle.url).concat(trackingParam).concat(colorParam).concat(vehicleOfferTypeParam);
}

const VehicleItem = ({
 vehicle, translations, route, colorParameter, vehicleOfferTypeParameter, index, listingFormUrl, pageName
}) => {
  return (
    <div className="sc-grid-row lc-vehicle-item">
      <div className="sc-grid-col-2 sc-hidden-m">&nbsp;</div>
      <div className="sc-text-left sc-grid-col-8 sc-grid-col-m-12 sc-grid-col-s-12">
        <a
          id={`vehicle-selection-link${index}`}
          onClick={() => {
            goToListingForm(vehicle, route, colorParameter, vehicleOfferTypeParameter, listingFormUrl);
          }}
          className="lc-vehicle-item-link"
        >
          <div>
            <p className="lc-vehicle-item-headline sc-font-bold">
              {displayHeadlineAttributes(vehicle)}
            </p>
            <p className="lc-vehicle-attributes">{displayAttributes(vehicle, translations, pageName)}</p>
          </div>
          <p className="sc-font-xxl lc-margin-left-auto lc-padding-left-l">&#8594;</p>
        </a>
      </div>
    </div>
  );
};

VehicleItem.propTypes = {
  vehicle: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  colorParameter: PropTypes.number.isRequired,
  vehicleOfferTypeParameter: PropTypes.string,
  index: PropTypes.string.isRequired,
  listingFormUrl: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired
};

export default VehicleItem;
