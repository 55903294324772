import { connect } from 'react-redux';
import NatCode from './component';

function mapStateToProps(state) {
  return {
    showBuildDate: state.configuration.toggles.showBuildDate,
    showCarPrivateBgTabs: state.configuration.toggles.showCarPrivateBgTabs
    };
}

export default connect(
  mapStateToProps
)(NatCode);
