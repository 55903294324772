import * as actionTypes from './actionTypes';
import * as defaultValues from '../../common/defaultValues';
import {
  index, fields, fieldsEmpty, fieldNames, regexLettersAndNumbers, regexNumbers
} from '../../common/hsnTsnConstants';
import { vehicleOfferTypeDefaultValue } from '../../common/constants';
import { firstRegistrationMonthAndYear, firstRegistrationValidation, firstRegistrationClear } from '../../common/validation';

export const initialState = {
  buildRange: false,
  hsnTsn: defaultValues.hsnTsn,
  firstRegistration: defaultValues.firstReg,
  fuel: defaultValues.selectStringHidden,
  doors: defaultValues.selectNumberHidden,
  colors: [],
  color: defaultValues.selectStringHidden,
  isFetchingColor: false,
  power: defaultValues.selectNumberHidden,
  equipmentLine: defaultValues.selectStringHidden,
  vehicleOfferType: {
    value: vehicleOfferTypeDefaultValue,
    isValid: true,
    isDisabled: false,
  },
  isFetchingMakes: false,
  isFetchingVehicleOfferTypes: false,
  vehicleOfferTypes: [],
  firstRegistrationMonthAndYear: {
    month: '0',
    year: '0',
    isValid: false,
    isPristine: true,
    monthPristine: true,
    yearPristine: true,
    limitIsOk: true,
    isDisabled: true
  },
  firstRegistrationValidation: {
    vehicleType: '',
    minYear: 100,
    maxYear: 0,
    mandatory: true,
    visible: true,
  },
};

const setDefault = (obj, idx) => {
  if (idx === fields.length) {
    return obj;
  }
  return setDefault({ ...obj, [fields[idx].name]: fields[idx].value }, idx + 1);
};
const setDisabled = (obj, idx) => {
  if (idx === fields.length) {
    return obj;
  }
  return setDisabled({
    ...obj,
    [fields[idx].name]: fieldsEmpty[idx].value
  }, idx + 1);
};

const updateFields = (name, value, state) => {
  const newState = {
    ...state,
    [name]: { ...state[name], value, isValid: value !== fields[index[name]].value.value }
  };

  return setDisabled(newState, index[name] + 1);
};

const checkIfFirstRegistrationIsValid = (state, action) => {
  return (action.value !== '0' && state.firstRegistration[action.otherField] !== '0');
};

const validate = (field, value) => {
  switch (field) {
    case fieldNames.hsn:
      return regexNumbers.test(value) && value.length === 4;
    case fieldNames.tsn:
      return regexLettersAndNumbers.test(value) && value.length === 3;
    default:
      return true;
  }
};

const allowChange = (field, value) => {
  switch (field) {
    case fieldNames.hsn:
      return value === '' || regexNumbers.test(value);
    case fieldNames.tsn:
      return value === '' || regexLettersAndNumbers.test(value);
    default:
      return true;
  }
};

const updateFirstRegAndLowerFields = (state, action) => {
  const newState = {
    ...state,
    firstRegistration: {
      ...state.firstRegistration,
      [action.field]: action.value,
      [`${action.field}Pristine`]: false,
      isPristine: state.firstRegistration[`${action.otherField}Pristine`],
      isValid: checkIfFirstRegistrationIsValid(state, action)
    }
  };
  return setDefault(newState, index[action.name] + 1);
};

const updateHsnTsnAndLowerFields = (state, action) => {
  const isFieldValid = validate(action.field, action.value);
  const newState = {
    ...state,
    hsnTsn: {
      ...state.hsnTsn,
      [action.field]: action.value,
      [`${action.field}Pristine`]: false,
      isPristine: state.hsnTsn[`${action.otherField}Pristine`],
      [`${action.field}Valid`]: isFieldValid,
      [`${action.otherField}Valid`]: state.hsnTsn[`${action.otherField}Valid`],
      isValid: isFieldValid && state.hsnTsn[`${action.otherField}Valid`]
    }
  };
  return setDefault(newState, index[action.name] + 1);
};
const updateOtherAndLowerFields = (state, action) => {
  const newState = {
    ...state,
    [action.field]: {
      ...state[action.field],
      options: action.data,
      value: (action.data.length === 1) ? action.data[0].id : fields[index[action.field]].value.value,
      isValid: action.data.length === 1,
      isHidden: false,
      isDisabled: action.data.length === 1
    }
  };
  return setDisabled(newState, index[action.field] + 1);
};

const hsnTsnData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_AND_UPDATE_LOWER_FIELDS_HSNTSN:
      return Object.assign({}, state, updateFields(action.name, action.value, state));
    case actionTypes.COLORS_REQUEST_HSNTSN:
      return { ...state, isFetchingColors: true };
    case actionTypes.COLORS_SUCCESS_HSNTSN:
      return {
        ...state,
        colors: action.colors,
        isFetchingColors: false
      };
    case actionTypes.COLORS_FAILURE_HSNTSN:
      return state;
    case actionTypes.CHANGE_HSN_TSN_AND_UPDATE_LOWER_FIELDS_HSNTSN:
      return allowChange(action.field, action.value)
        ? updateHsnTsnAndLowerFields(state, action)
        : state;
    case actionTypes.SHOW_FIRST_REG_HSNTSN:
      return {
        ...state,
        firstRegistration: { ...state.firstRegistration, isDisabled: false },
      };
    case actionTypes.SHOW_FIRST_REG_HSN_TSN:
      return {
        ...state,
        firstRegistrationMonthAndYear: { ...state.firstRegistrationMonthAndYear, isDisabled: false },
      };
    case actionTypes.CHANGE_FIRST_REG_AND_UPDATE_LOWER_FIELDS_HSNTSN:
      return Object.assign({}, updateFirstRegAndLowerFields(state, action));
    case actionTypes.TAXONOMY_SUCCESS_HSNTSN:
      return updateOtherAndLowerFields(state, action);
    case actionTypes.TAXONOMY_FAILURE_HSNTSN:
      return state;
    case actionTypes.SHOW_COLOR_HSNTSN:
      return {
        ...state,
        color: { ...state.color, isDisabled: false, isHidden: false }
      };
    case actionTypes.SHOW_EQUIPMENT_LINE_HSNTSN:
      return {
        ...state,
        equipmentLine: { ...state.equipmentLine, isHidden: false, isDisabled: true }
      };
    case actionTypes.VEHICLE_OFFER_TYPES_REQUEST_HSNTSN:
      return { ...state, isFetchingVehicleOfferTypes: true };
    case actionTypes.VEHICLE_OFFER_TYPES_SUCCESS_HSNTSN:
      return {
        ...state,
        vehicleOfferTypes: action.vehicleOfferTypes,
        isFetchingVehicleOfferTypes: false
      };
    case actionTypes.VEHICLE_OFFER_TYPES_FAILURE_HSNTSN:
      return state;
    case actionTypes.CHANGE_VEHICLE_OFFER_TYPE:
      return {
        ...state,
        vehicleOfferType: {
          ...state.vehicleOfferType,
          value: action.value
        }
      };
    case actionTypes.FIRST_REG_CHANGE:
      return {
        ...state,
        firstRegistrationMonthAndYear: {
          ...state.firstRegistrationMonthAndYear,
          ...firstRegistrationMonthAndYear(state, action.value, action.otherField),
        },
      };
    case actionTypes.FIRST_REG_CHANGE_VALIDATION: {
      const tempState = {
        ...state,
        firstRegistrationValidation: firstRegistrationValidation(state, action.value),
      };

      return {
        ...tempState,
        firstRegistrationMonthAndYear: {
          ...tempState.firstRegistrationMonthAndYear,
          ...firstRegistrationClear(),
        },
      };
    }
    case actionTypes.CHANGE_BUILD_RANGE_HSNTSN: {
      return {
        ...state,
        buildRange: action.value,
      };
    }
    case actionTypes.UPDATE_LOWER_FIELDS_HSNTSN:
      return setDefault(state, index[fieldNames.hsnTsn] + 1);
    default:
      return state;
  }
};
export default hsnTsnData;
