import { connect } from 'react-redux';
import {
  changeAndUpdateLowerFields, getNextData, showEquipmentLine, clearResults, fillManualLink, buttonReset
} from '../actions';
import DropDown from '../../../common/components/dropdown';
import { fieldNames } from '../../../common/constants';

function mapStateToProps(state) {
  const pageName = state.configuration.translationsPageName.makemodel.pageName;
  return {
    label: state.translations[pageName].data.powerLabel,
    name: fieldNames.power,
    componentState: state.makeModelData.power,
    defaultText: state.translations[pageName].data.pleaseChoose,
    options: state.makeModelData.power.options
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange: (e) => {
      dispatch(changeAndUpdateLowerFields(parseInt(e.target.value, 10), fieldNames.power));
      if (e.target.value !== '0') {
        dispatch(getNextData(fieldNames.power));
        dispatch(showEquipmentLine());
        dispatch(fillManualLink());
      }
      dispatch(clearResults());
      dispatch(buttonReset());
    }
  };
}
const Power = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDown);

export default Power;
