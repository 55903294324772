import React from 'react';
import { PropTypes } from 'prop-types';

const Button = ({
  text, className, enable, onClick, id, disabled
}) => {
  return (
    <button
      id={id}
      className={className}
      onClick={onClick}
      data-enabled={enable}
      disabled={disabled}
    >
      {text}

    </button>
  );
};
Button.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  enable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
export default Button;
