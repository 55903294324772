import React from 'react';
import { PropTypes } from 'prop-types';

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const DateSelect = ({
  label, componentState, years, translations, monthChange, yearChange
}) => (
  <div className="lc-component date-select">
    <span className={`lc-date-label ${componentState.isDisabled ? 'sc-font-silent' : ''}`}>{label}</span>
    <div className="sc-input-group">
      <select
        className="sc-input"
        disabled={componentState.isDisabled}
        value={componentState.month}
        onChange={monthChange}
        data-valid={(componentState.isValid || componentState.isPristine || componentState.month !== '0')}
      >
        <option className="lc-padding-left-l" value="0">{translations.month}</option>
        {months.map((x) => {
          return <option className="lc-padding-left-l" value={x > 9 ? x : `0${x}`}>{x > 9 ? x : `0${x}`}</option>;
        })}
      </select>
      <select
        className="sc-input"
        disabled={componentState.isDisabled}
        value={componentState.year}
        onChange={yearChange}
        data-valid={(componentState.isValid || componentState.isPristine || componentState.year !== '0')}
      >
        <option className="lc-padding-left-l" value="0">{translations.year}</option>
        {years.map((x) => {
          return <option className="lc-padding-left-l" value={x}>{x}</option>;
        })}
      </select>
    </div>
    {
        (!(componentState.isValid || componentState.isPristine) && componentState.month === '0') ? (
          <span className="error sc-font-s sc-grid-col-12">{translations.monthErrorMessage}</span>
        )
          : null}
    {
        (!(componentState.isValid || componentState.isPristine) && componentState.year === '0') ? (
          <span className="error sc-font-s sc-grid-col-12">{translations.yearErrorMessage}</span>
        )
          : null}
  </div>
);

DateSelect.propTypes = {
  label: PropTypes.string.isRequired,
  componentState: PropTypes.object.isRequired,
  years: PropTypes.array.isRequired,
  translations: PropTypes.object.isRequired,
  monthChange: PropTypes.func.isRequired,
  yearChange: PropTypes.func.isRequired,
};

export default DateSelect;
