import * as defaultValues from './defaultValues';

export const kwToHpRatio = 1.35962162;
// eslint-disable-next-line
export const regexEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
// eslint-disable-next-line
export const regexUrl = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
export const regexTags = /<\/?\s?.*\s?\/?>/;
export const regexLettersAndNumbers = /^[a-z0-9]+$/i;
export const regexNumbers = /^[0-9]+$/;
export const hsnLength = 4;
export const tsnLength = 3;
export const hsnInputType = 'tel';
export const tsnInputType = 'text';
export const index = {
  vehicleOfferType: 0,
  hsnTsn: 1,
  firstRegistration: 2,
  doors: 3,
  fuel: 4,
  power: 5,
  equipmentLine: 6,
  color: 7,
  result: 8
};
export const fieldNames = {
  vehicleOfferType: 'vehicleOfferType',
  hsnTsn: 'hsnTsn',
  hsn: 'hsn',
  tsn: 'tsn',
  firstRegistration: 'firstRegistration',
  doors: 'doors',
  fuel: 'fuel',
  equipmentLine: 'equipmentLine',
  color: 'color',
  power: 'power',
  result: 'result',
  month: 'month',
  year: 'year',
};
export const fields = [{ name: fieldNames.vehicleOfferType, value: defaultValues.selectStringDisabled },
  { name: fieldNames.hsntsn, value: defaultValues.hsnTsn },
  { name: fieldNames.firstRegistration, value: defaultValues.firstReg },
  { name: fieldNames.doors, value: defaultValues.selectNumberHidden },
  { name: fieldNames.fuel, value: defaultValues.selectStringHidden },
  { name: fieldNames.power, value: defaultValues.selectNumberHidden },
  { name: fieldNames.equipmentLine, value: defaultValues.selectStringHidden },
  { name: fieldNames.color, value: defaultValues.selectStringHidden },
  { name: fieldNames.result, value: [] }];
export const fieldsEmpty = [{ name: fieldNames.vehicleOfferType, value: defaultValues.selectStringDisabled },
  { name: fieldNames.hsntsn, value: defaultValues.hsnTsn },
  { name: fieldNames.firstRegistration, value: defaultValues.firstReg },
  { name: fieldNames.doors, value: defaultValues.selectNumberDisabled },
  { name: fieldNames.fuel, value: defaultValues.selectStringDisabled },
  { name: fieldNames.power, value: defaultValues.selectNumberDisabled },
  { name: fieldNames.equipmentLine, value: defaultValues.selectStringDisabled },
  { name: fieldNames.color, value: defaultValues.selectStringDisabled },
  { name: fieldNames.result, value: [] }];
export const taxonomyStatus = {
  valid: 'TAXONOMY_VALID',
  error: 'TAXONOMY_ERROR',
  skip: 'TAXONOMY_SKIP'
};
