import { CLEAR_VEHICLES_RESULT } from '../../app/actionTypes';
import * as actionTypes from './actionTypes';
import { getNatResult } from '../services/taxonomyFilterApi';

export const changeNatCode = (value) => {
  return {
    type: actionTypes.NATCODE_CHANGE,
    value,
  };
};
export const getData = () => {
  return (dispatch, getState) => {
    const state = getState();
    // Do not dispatch next field after equipment because it is color
    if (state.natCode.natCode.isValid && state.natCode.firstRegistration.isValid) {
      return dispatch(getNatResult(state));
    }
    return null;
  };
};
export const changeFirstRegistration = (field, otherField, value) => {
  return {
    type: actionTypes.NAT_CODE_CHANGE_FIRST_REG,
    field,
    otherField,
    value
  };
};
export const enableFirstReg = () => {
  return {
    type: actionTypes.NAT_CODE_ENABLE_FIRST_REG,
  };
};
export const clearResults = () => {
  return {
    type: CLEAR_VEHICLES_RESULT,
  };
};
export const changeBuildRange = value => ({
  type: actionTypes.CHANGE_BUILD_RANGE_NATCODE,
  value,
});
