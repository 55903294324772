import * as makeModelActions from '../makemodel/actions';
import * as bikePrivateActions from '../bikeprivate/actions';

const getData = url => fetch(url, { credentials: 'include' })
  .then(response => response.json());

export const fetchMakesAsync = (makesUrl, getMakes = getData) => (dispatch) => {
  dispatch(makeModelActions.fetchMakes());
  return getMakes(makesUrl)
    .then((json) => {
      dispatch(makeModelActions.makesSuccess(json.topMakes, json.makes));
    })
    .catch((error) => {
      dispatch(makeModelActions.makesFailure(error));
    });
};

export const fetchModelAsync = (makeId, modelsUrl, getModels = getData) => (dispatch) => {
  const url = `${modelsUrl}/${makeId}`;
  return getModels(url)
      .then((json) => {
        dispatch(bikePrivateActions.updateModelOptions(json.options));
      })
      .catch(() => {
          dispatch(bikePrivateActions.updateModelOptions([]));
      });
};

const getColorData = () => fetch('/listing-creation-entry-point/lookups/colors', { credentials: 'include' })
  .then(response => response.json());

export const fetchColorsAsync = (getColors = getColorData) => (dispatch) => {
  dispatch(makeModelActions.fetchColors());
  return getColors()
    .then((json) => {
      dispatch(makeModelActions.colorsSuccess(json.colors));
    })
    .catch((error) => {
      dispatch(makeModelActions.colorsFailure(error));
    });
};

const getVehicleOfferTypeData = () => fetch('/listing-creation-entry-point/lookups/vehicle-offer-types', { credentials: 'include' })
  .then(response => response.json());

export const fetchVehicleOfferTypesAsync = (getVehicleOfferTypes = getVehicleOfferTypeData) => (dispatch) => {
  dispatch(makeModelActions.fetchVehicleOfferTypes());
  return getVehicleOfferTypes()
    .then((json) => {
      dispatch(makeModelActions.vehicleOfferTypesSuccess(json.vehicleOfferTypes));
    })
    .catch((error) => {
      dispatch(makeModelActions.vehicleOfferTypesFailure(error));
    });
};
