import React from 'react';
import { PropTypes } from 'prop-types';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import routes from '../app/router/routes';
import { notificationTarget, notificationType } from './constants';

export function setShowClass(uid) {
    setTimeout(() => {
        document.getElementById(uid).className = 'show';
    }, 50);
}

const getTabRouteFromString = (someText) => {
    const temp = someText.substring(someText.indexOf('#/') + 2);
    const temp2 = temp.substring(0, temp.indexOf('"'));

    return Object.keys(routes).includes(temp2) ? temp2 : '';
};

const NotificationItem = ({
 title, text, uid, key, type, timeout
}) => {
    const tabRoute = getTabRouteFromString(text) !== '' ? getTabRouteFromString(text) : getTabRouteFromString(title);
    return tabRoute === '' ? (
      <as24-notification
        type={type}
        id={uid}
        key={key}
        target={`#${notificationTarget}`}
        title={title}
        onload={setShowClass(uid)}
        {...(timeout ? { timeout } : {})}
      >
        {parse(text)}
      </as24-notification>
    ) : (
      <Link to={tabRoute}>
        <as24-notification
          data-tabroute="1"
          type={type}
          id={uid}
          key={key}
          target={`#${notificationTarget}`}
          title={title}
          onload={setShowClass(uid)}
          {...(timeout ? { timeout } : {})}
        >
          {parse(text)}
        </as24-notification>
      </Link>
    );
};

NotificationItem.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    uid: PropTypes.string,
    key: PropTypes.string,
    type: PropTypes.string,
    timeout: PropTypes.string,
    errors: PropTypes.array
};

NotificationItem.defaultProps = {
    title: '',
    text: '',
    uid: '',
    key: '',
    type: notificationType.error,
    timeout: '',
    errors: []
};

export default NotificationItem;
