import React from 'react';
import { PropTypes } from 'prop-types';
import HsnTsn from './elements/hsntsn';
import Door from './containers/door';
import Fuel from './containers/fuel';
import Color from './elements/color';
import FirstRegistration from './containers/firstRegistration';
import Power from './containers/power';
import EquipmentLine from './containers/equipmentLine';
import BuildRange from './containers/buildDate';

const HsnTsnData = ({ showBuildDate, showCarPrivateBgTabs }) => {
  const formDivClass = `sc-clearfix sc-block-center lc-padding-bottom-xxl ${(showCarPrivateBgTabs
    ? 'lc-padding-horizontal-xxxl sc-grid-col-12'
    : 'lc-padding-horizontal-l sc-grid-col-6 sc-grid-col-s-12')}`;
  return (
    <div className="sc-grid-row">
      <div className="sc-grid-col-3 sc-hidden-s" />
      <div className={formDivClass}>
        <HsnTsn />
        {window.currentCulture === 'de-DE' && showBuildDate ? <BuildRange /> : null}
        <FirstRegistration />
        <Door />
        <Fuel />
        <Power />
        <EquipmentLine />
        <Color />
      </div>
    </div>
  );
};

HsnTsnData.propTypes = {
  hsnTsnData: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  showCarPrivateBgTabs: PropTypes.bool,
  showBuildDate: PropTypes.bool,
};

export default HsnTsnData;
