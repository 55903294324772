import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { notificationShow } from './actions';
import GlobalTechnicalError from './globalTechnicalError';

export function getNotificationUid() {
    return uuidv4();
}

export const globalTechnicalErrorNotification = (dispatch, title, text) => {
    const errorUid = getNotificationUid();
    dispatch(
        notificationShow(() => React.createElement(GlobalTechnicalError, {
                title,
                text,
                uid: errorUid,
                key: errorUid
            }))
    );
};
