import {
  getVehiclesFailure, getVehiclesRequest, getVehiclesSuccess, getVehiclesVinError
} from './vinActions';
import { globalTechnicalErrorNotification } from '../../notifications/helpers';

const vinSearchClient = (vin, secret) => fetch(window.trackingConfiguration.environment === 'live'
  ? 'https://listing-creation-entry-point.a.autoscout24.com/listing-creation-entry-point/taxonomy-data'
  : '/listing-creation-entry-point/taxonomy-data', {
  credentials: 'same-origin',
  method: 'post',
  headers: {
    'Content-Type': 'application/json',
    'csrf-token': window.csrfToken
  },
  body: JSON.stringify({ vin, secret, culture: window.currentCulture })
}).then(response => response.json());

const getSuperSecret = () => fetch('/listing-creation-entry-point/getsecret', {
  credentials: 'same-origin',
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    pragma: 'no-cache',
    'cache-control': 'no-cache',
    'csrf-token': window.csrfToken
  }
}).then(response => response.text());

const getVehiclesAsync = (vin, fetchVehicles = vinSearchClient, superSecret = getSuperSecret) => (dispatch) => {
  dispatch(getVehiclesRequest());
  const hsnTsnLink = '/listing-creation-entry-point#/hsntsn';
  const errorMessage = 'Momentan ist die FIN-Abfrage für Ihr Fahrzeug nicht möglich !!';
  const errorLinkText = `Bitte versuchen Sie es später noch einmal oder inserieren Sie mit Ihrer <a href="${hsnTsnLink}">Schlüsselnummer</a>`;

  return superSecret().then(sec => fetchVehicles(vin, sec))
    .then((json) => {
      if (json.status === 'success') {
        dispatch(getVehiclesSuccess(json.vehicles));
      } else if (json.status === 'invalid') {
        dispatch(getVehiclesVinError(json.vinErrors[0]));
      } else if (json.status === 'failure') {
        globalTechnicalErrorNotification(dispatch, json.vinLookupErrorMessage, json.vinLookupErrorLinkText);
        dispatch(getVehiclesFailure());
      }
    })
    .catch(() => {
      globalTechnicalErrorNotification(dispatch, errorMessage, errorLinkText);
      dispatch(getVehiclesFailure());
    });
};

export default getVehiclesAsync;
