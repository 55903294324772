import React from 'react';
import { PropTypes } from 'prop-types';

const LoadingSpinner = ({ className, isLoading, message }) => (
    <div className={isLoading ? className : 'sc-hidden'}>
        <div className="lc-padding-top-xxxl sc-absolute-center">
            <div className="sc-spinner-loading orange" />
        </div>
        <div className="sc-absolute-center sc-font-bold">{message}</div>
    </div>
);

LoadingSpinner.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    message: PropTypes.string,
};

export default LoadingSpinner;
