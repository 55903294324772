import React from 'react';
import { PropTypes } from 'prop-types';
import NotificationBox from '../notifications/notificationBox';

const PageHeader = ({ translations, showDealerHeader }) => {
  const wrap = header => (<div id="pageheader" className="lc-box-top ">{header}</div>);

  return wrap(
    <div>
      <NotificationBox />
      {
        showDealerHeader ? (
          <>
            <ul className="lc-breadcrumbs lc-breadcrumbs--viewport-s lc-margin-left-l">
              <li><a href={translations.common.data.cockpitLink}>{translations.common.data.cockpit}</a></li>
              <li><span>{translations.common.data.insertion}</span></li>
            </ul>
            <h1 className="lc-box-top-headline sc-font">{translations.common.data.insertion}</h1>
          </>
        ) : null
      }
    </div>
  );
};

PageHeader.propTypes = {
  translations: PropTypes.object,
  showDealerHeader: PropTypes.bool,
};

export default PageHeader;
