import { connect } from 'react-redux';
import HsnTsnData from './component';

function mapStateToProps(state) {
  return {
    hsnTsnData: state.hsnTsnData,
    translations: state.translations,
    showCarPrivateBgTabs: state.configuration.toggles.showCarPrivateBgTabs,
    showBuildDate: state.configuration.toggles.showBuildDate,
  };
}

export default connect(
  mapStateToProps
)(HsnTsnData);
