import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { changeHsnTsnAndUpdateLowerFields, validateAndShowFirstReg, clearResults } from '../actions';
import {
  fieldNames, hsnLength, tsnLength, hsnInputType, tsnInputType
} from '../../../common/hsnTsnConstants';
import Input from '../../../common/components/input';

export const HsnTsnInput = ({
  componentState, hsnLabel, tsnLabel, hsnPlaceholder, tsnPlaceholder, hsnChange, tsnChange, hsnErrorMessage, tsnErrorMessage
}) => (
  <div className="sc-grid-row">
    <div className="sc-grid-col-6">
      <Input
        name={fieldNames.hsn}
        value={componentState.hsn}
        label={hsnLabel}
        type={hsnInputType}
        placeholder={hsnPlaceholder}
        minLength={hsnLength}
        maxLength={hsnLength}
        isValid={componentState.hsnValid}
        isPristine={componentState.hsnPristine}
        errorMessage={hsnErrorMessage}
        onChange={hsnChange}
      />
    </div>
    <div className="sc-grid-col-6">
      <Input
        name={fieldNames.tsn}
        value={componentState.tsn}
        label={tsnLabel}
        type={tsnInputType}
        placeholder={tsnPlaceholder}
        minLength={tsnLength}
        maxLength={tsnLength}
        isValid={componentState.tsnValid}
        isPristine={componentState.tsnPristine}
        errorMessage={tsnErrorMessage}
        onChange={tsnChange}
      />
    </div>
  </div>
);
HsnTsnInput.propTypes = {
  componentState: PropTypes.object.isRequired,
  hsnLabel: PropTypes.string,
  tsnLabel: PropTypes.string,
  hsnPlaceholder: PropTypes.string,
  tsnPlaceholder: PropTypes.string,
  hsnErrorMessage: PropTypes.string,
  tsnErrorMessage: PropTypes.string,
  hsnChange: PropTypes.func.isRequired,
  tsnChange: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    componentState: state.hsnTsnData.hsnTsn,
    hsnLabel: state.translations.hsntsn.data.hsnLabel,
    tsnLabel: state.translations.hsntsn.data.tsnLabel,
    hsnPlaceholder: state.translations.hsntsn.data.hsnPlaceholder,
    tsnPlaceholder: state.translations.hsntsn.data.tsnPlaceholder,
    hsnErrorMessage: state.translations.hsntsn.data.hsnErrorMessage,
    tsnErrorMessage: state.translations.hsntsn.data.tsnErrorMessage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hsnChange: (e) => {
      dispatch(changeHsnTsnAndUpdateLowerFields(fieldNames.hsn, fieldNames.tsn, e.target.value, fieldNames.hsnTsn));
      dispatch(validateAndShowFirstReg(fieldNames.hsnTsn));
      dispatch(clearResults());
    },
    tsnChange: (e) => {
      dispatch(changeHsnTsnAndUpdateLowerFields(fieldNames.tsn, fieldNames.hsn, e.target.value, fieldNames.hsnTsn));
      dispatch(validateAndShowFirstReg(fieldNames.hsnTsn));
      dispatch(clearResults());
    }
  };
}

const HsnTsn = connect(
  mapStateToProps,
  mapDispatchToProps
)(HsnTsnInput);

export default HsnTsn;
