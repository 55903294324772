import React from 'react';
import ReactDOM from 'react-dom';
import { hot } from 'react-hot-ts';
import { fromSerializedJson } from '../config/Config';
import AppContainer from '../containers/AppContainer';

// Where in the dom sits the configuration dump & render target - Keep in sync w/ backend
const configSourceAndRenderTargetDomId = 'main';

document.addEventListener('DOMContentLoaded', () => {
  const config = fromSerializedJson(configSourceAndRenderTargetDomId);
  // Bail out if config is missing
  if (config === undefined) {
    throw new Error(
      `Client bootstrap error: Make sure configuration is available as serialized JSON at DOM element w/ id: ${configSourceAndRenderTargetDomId}`
    );
  }

  const target = document.getElementById(configSourceAndRenderTargetDomId);

  if (target) {
    const App = (
        <AppContainer />
    );

    // Hydrate or Render depending on whether we have done successful SSR or not
    if (target.dataset.ssr === 'true') {
      hot(module)(ReactDOM.hydrate(App, target));
    } else {
      hot(module)(ReactDOM.render(App, target));
    }
  }

  // Hot Module Replacement API
  if (module.hot) {
    // Application hot-reloading
    module.hot.accept('../containers/AppContainer', () => {
      ReactDOM.render(
          <AppContainer />,
        target
      );
    });
  }
});

// dummy comment to test propagation
