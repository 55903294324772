import { connect } from 'react-redux';
import DropDown from '../../../common/components/dropdown';
import { fieldNames } from '../../../common/constants';
import { changeFieldValue } from '../actions';

function mapStateToProps(state) {
  return {
    label: state.translations.bikePrivate.data.modelLabel,
    name: fieldNames.model,
    componentState: state.bikePrivate.model,
    defaultText: state.translations.bikePrivate.data.pleaseChoose,
    options: state.bikePrivate.model.options
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange: (e) => {
      dispatch(changeFieldValue(fieldNames.model, e.target.value));
    }
  };
}
const Model = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDown);

export default Model;
