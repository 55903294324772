import * as actionTypes from './actionTypes';
import {
  CLEAR_VEHICLES_RESULT,
  GET_VEHICLES_SUCCESS,
  SET_COLOR_PARAMETER,
  SET_VEHICLE_OFFER_TYPE_PARAMETER
} from '../../app/actionTypes';
import * as lookupApi from '../services/hsnTsnLookupApi';
import * as taxonomyApi from '../services/hsnTsnTaxonomyFilterApi';
import * as constants from '../../common/hsnTsnConstants';

export const changeAndUpdateLowerFields = (value, name) => {
  return {
    type: actionTypes.CHANGE_AND_UPDATE_LOWER_FIELDS_HSNTSN,
    value,
    name
  };
};
export const updateLowerFields = (value, name) => {
  return {
    type: actionTypes.UPDATE_LOWER_FIELDS_HSNTSN,
    value,
    name
  };
};
export const fetchColors = () => {
  return {
    type: actionTypes.COLORS_REQUEST_HSNTSN
  };
};
export const colorsSuccess = (colors) => {
  return {
    type: actionTypes.COLORS_SUCCESS_HSNTSN,
    colors
  };
};
export const colorsFailure = (error) => {
  return {
    type: actionTypes.COLORS_FAILURE_HSNTSN,
    error
  };
};
export const getColorsAsync = (colors) => {
  if (Object.keys(colors).length > 0) {
    return null;
  }
  return lookupApi.fetchColorsAsync();
};
export const showFirstReg = () => {
  return {
    type: actionTypes.SHOW_FIRST_REG_HSNTSN
  };
};
export const showFirstRegHsnTsn = () => {
  return {
    type: actionTypes.SHOW_FIRST_REG_HSN_TSN
  };
};
export const validateAndShowFirstReg = (field) => {
  return (dispatch, getState) => {
    const state = getState();
    if (state.hsnTsnData[field].isValid) {
      return dispatch(showFirstReg());
    }
    return null;
  };
};
export const changeFirstRegistrationAndUpdateLowerFields = (field, otherField, value, name) => {
  return {
    type: actionTypes.CHANGE_FIRST_REG_AND_UPDATE_LOWER_FIELDS_HSNTSN,
    field,
    otherField,
    value,
    name
  };
};
export const changeHsnTsnAndUpdateLowerFields = (field, otherField, value, name) => {
  return {
    type: actionTypes.CHANGE_HSN_TSN_AND_UPDATE_LOWER_FIELDS_HSNTSN,
    field,
    otherField,
    value,
    name
  };
};
export const getNextData = (field) => {
  return (dispatch, getState) => {
    const state = getState();
    // Do not dispatch next field after equipment because it is color
    if (state.hsnTsnData[field].isValid && field !== constants.fields[constants.index.equipmentLine].name) {
      return dispatch(taxonomyApi.getNextTaxonomyData(constants.fields[constants.index[field] + 1].name, state));
    }
    return null;
  };
};
export const taxonomySuccess = (data, field) => {
  return {
    type: actionTypes.TAXONOMY_SUCCESS_HSNTSN,
    data,
    field
  };
};
export const taxonomyResultSuccess = (data, isBuildRange) => {
  return {
    type: GET_VEHICLES_SUCCESS,
    vehicles: data.versions,
    isBuildRange,
  };
};
export const taxonomyFailure = (error) => {
  return {
    type: actionTypes.TAXONOMY_FAILURE_HSNTSN,
    error
  };
};
export const showColor = () => {
  return {
    type: actionTypes.SHOW_COLOR_HSNTSN
  };
};
export const setColorParameter = (value) => {
  return {
    type: SET_COLOR_PARAMETER,
    value
  };
};
export const setVehicleOfferTypeParameter = (value) => {
  return {
    type: SET_VEHICLE_OFFER_TYPE_PARAMETER,
    value
  };
};
export const showEquipmentLine = () => {
  return {
    type: actionTypes.SHOW_EQUIPMENT_LINE_HSNTSN
  };
};
export const clearResults = () => {
  return {
    type: CLEAR_VEHICLES_RESULT
  };
};
export const getVehicleOfferTypesAsync = (vehicleOfferTypes) => {
  if (Object.keys(vehicleOfferTypes).length > 0) {
    return null;
  }
  return lookupApi.fetchVehicleOfferTypesAsync();
};
export const fetchVehicleOfferTypes = () => {
  return {
    type: actionTypes.VEHICLE_OFFER_TYPES_REQUEST_HSNTSN
  };
};
export const vehicleOfferTypesSuccess = (vehicleOfferTypes) => {
  return {
    type: actionTypes.VEHICLE_OFFER_TYPES_SUCCESS_HSNTSN,
    vehicleOfferTypes
  };
};
export const vehicleOfferTypesFailure = (error) => {
  return {
    type: actionTypes.VEHICLE_OFFER_TYPES_FAILURE_HSNTSN,
    error
  };
};
export const changeVehicleOfferType = (value) => {
  return {
    type: actionTypes.CHANGE_VEHICLE_OFFER_TYPE,
    value
  };
};

export const changeFirstRegistration = (field, otherField, value) => ({
  type: actionTypes.FIRST_REG_CHANGE,
  field,
  otherField,
  value,
});
export const changeFirstRegistrationValidation = value => ({
  type: actionTypes.FIRST_REG_CHANGE_VALIDATION,
  value,
});
export const changeBuildRange = value => ({
  type: actionTypes.CHANGE_BUILD_RANGE_HSNTSN,
  value,
});
