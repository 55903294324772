import { LicensePlateError, publishError } from '../../common/publishError';

// another async possible for the fetch
const getVehicleData = licensePlateUrl => fetch(licensePlateUrl)
    .then((response) => {
        if (response.ok) {
            return response.json();
        }
        return response.json().catch(() => response.statusText).then((bd) => {
            const status = response.status;
            if (status >= 500) {
                publishError(LicensePlateError('Could not get response from license plate', {
                    code: status,
                    reason: response.statusText
                }));
            }
            throw new Error(bd);
        });
    });

// another async possible for the getVehicles
export const fetchVehicles = (licensePlateUrl, getVehicles = getVehicleData) => getVehicles(licensePlateUrl)
    .then((response) => {
        return response.vehicles;
    }).catch((error) => {
        throw error;
    });
