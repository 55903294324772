import * as actionTypes from './actionTypes';
import * as defaultValues from '../../common/defaultValues';

export const initialState = {
  buildRange: false,
  natCode: {
    value: '',
    isValid: false,
    isPristine: true,
  },
  firstRegistration: defaultValues.firstReg,
};
const checkIfFirstRegistrationIsValid = (state, action) => {
  return (action.value !== '0' && state.firstRegistration[action.otherField] !== '0');
};

const natCode = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NATCODE_CHANGE: {
      return {
        ...state,
        natCode: {
          ...state.natCode,
          value: action.value,
          isValid: action.value.length > 3,
          isPristine: false,
        }
      };
    }
    case actionTypes.NAT_CODE_CHANGE_FIRST_REG: {
      return {
        ...state,
        firstRegistration: {
          ...state.firstRegistration,
          [action.field]: action.value,
          [`${action.field}Pristine`]: false,
          isPristine: state.firstRegistration[`${action.otherField}Pristine`],
          isValid: checkIfFirstRegistrationIsValid(state, action)
        }
      };
    }
    case actionTypes.NAT_CODE_ENABLE_FIRST_REG: {
      return {
        ...state,
        firstRegistration: {
          ...initialState.firstRegistration,
          isDisabled: !state.natCode.isValid,
        }
      };
    }
    case actionTypes.CHANGE_BUILD_RANGE_NATCODE: {
      return {
        ...state,
        buildRange: action.value,
      };
    }
    default:
      return state;
  }
};
export default natCode;
