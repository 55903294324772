import * as actionTypes from './actionTypes';
import * as defaultValues from '../../common/defaultValues';

export const initialState = {
  make: { ...defaultValues.selectString, isPristine: true },
  makes: [],
  topMakes: [],
  model: { ...defaultValues.selectStringDisabled, isPristine: true },
  isFetchingMakes: false,

};

const updateField = (state, action) => {
  return ({
    ...state,
    [action.name]: {
      ...state[action.name], value: action.value, isValid: action.value !== '0', isPristine: false
    }
  });
};

const bikePrivate = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_VEHICLES_RESULT:
      return initialState;
    case actionTypes.CHANGE_FIELD_VALUE:
      return updateField(state, action);
    case actionTypes.UPDATE_MODEL_OPTIONS:
      return { ...state, model: { ...state.model, options: action.options, isDisabled: action.options.length === 0 } };
    case actionTypes.MAKES_SUCCESS:
      return {
        ...state,
        topMakes: action.topMakes,
        makes: action.makes,
      };
    case actionTypes.TOUCH_ALL_FIELDS:
      return { ...state, model: { ...state.model, isPristine: false }, make: { ...state.make, isPristine: false } };
    default:
      return state;
  }
};
export default bikePrivate;
