const DatSupportedMakes = {
  personal: [
    { make: 'Abarth', year: 2007 },
    { make: 'Alfa Romeo', year: 2000 },
    { make: 'Audi', year: 1996 },
    { make: 'BMW', year: 1990 },
    { make: 'Cadillac', year: 2015 },
    { make: 'Chevrolet', year: 2005 },
    { make: 'Chrysler', year: 2006 },
    { make: 'Citroën', year: 2000 },
    { make: 'Corvette', year: 2015 },
    { make: 'Dacia', year: 2005 },
    { make: 'Daihatsu', year: 2003 },
    { make: 'Dodge', year: 2006 },
    { make: 'DS', year: 2015 },
    { make: 'Fiat', year: 2000 },
    { make: 'Ford', year: 1996 },
    { make: 'Honda', year: 2003 },
    { make: 'Hyundai', year: 2000 },
    { make: 'Infiniti', year: 2003 },
    { make: 'Jaguar', year: 2000 },
    { make: 'Jeep', year: 2006 },
    { make: 'Kia', year: 2004 },
    { make: 'LADA', year: 2000 },
    { make: 'Lancia', year: 2000 },
    { make: 'Land Rover', year: 2007 },
    { make: 'Lexus', year: 1998 },
    { make: 'Maserati', year: 2002 },
    { make: 'Mazda', year: 2006 },
    { make: 'Mercedes-Benz', year: 1990 },
    { make: 'MINI', year: 2001 },
    { make: 'Mitsubishi', year: 2002 },
    { make: 'Nissan', year: 2004 },
    { make: 'Opel', year: 1998 },
    { make: 'Peugeot', year: 2000 },
    { make: 'Porsche', year: 1996 },
    { make: 'Renault', year: 2000 },
    { make: 'Saab', year: 2000 },
    { make: 'Seat', year: 1999 },
    { make: 'Škoda', year: 1996 },
    { make: 'Smart', year: 1999 },
    { make: 'SsangYong', year: 2007 },
    { make: 'Subaru', year: 2000 },
    { make: 'Suzuki', year: 2000 },
    { make: 'Tesla', year: 2015 },
    { make: 'Toyota', year: 1998 },
    { make: 'Volkswagen', year: 1996 },
    { make: 'Volvo', year: 2002 },
  ],
  transporters: [
    { make: 'Citroën', year: 2000 },
    { make: 'Fiat', year: 2000 },
    { make: 'Ford', year: 1996 },
    { make: 'Hyundai', year: 2000 },
    { make: 'Kia', year: 2004 },
    { make: 'Mercedes-Benz', year: 1996 },
    { make: 'Nissan', year: 2004 },
    { make: 'Opel', year: 1998 },
    { make: 'Peugeot', year: 2000 },
    { make: 'Renault', year: 2000 },
    { make: 'Toyota', year: 1998 },
    { make: 'Volkswagen', year: 1996 },
  ],
};

export default DatSupportedMakes;
