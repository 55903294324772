export const translationParams = {
  pageheader: 'pageheader',
  common: 'common',
  vehicle: 'vehicle',
  carSourcingVehicle: 'carSourcingVehicle',
  carSourcingMakemodel: 'carSourcingMakemodel',
  vinform: 'vinform',
  makemodel: 'makemodel',
  hsntsn: 'hsntsn',
  natCode: 'natCode',
  countries: 'countries',
  bikePrivate: 'bikePrivate',
  carPrivate: 'carPrivate',
  licensePlate: 'licensePlate'
};
