import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, Route } from 'react-router-dom';

import Vehicle from '../vehicle/vehicleListContainer';
import { routeEventTrack } from '../tracking/tracking';

const generateBody = entryPoint => <Route exact path={entryPoint.route} component={entryPoint.component} />;

const getVehicleList = () => {
  const { showVehicleList } = useSelector(state => ({
    showVehicleList: state.configuration.toggles.showVehicleList,
  }), shallowEqual);
  return showVehicleList ? <Vehicle /> : null;
};

export const onlyOneEntryPoint = allowedEntryPointWithOutHome => allowedEntryPointWithOutHome.length <= 1;

export const contentWrapperWithTabs = (allowedEntryPointWithOutHome, translations, path) => {
  if (onlyOneEntryPoint(allowedEntryPointWithOutHome)) {
    return [];
  }

  const numberOfTabs = allowedEntryPointWithOutHome.length;
  const tabLabelCss = `lc-tab-link-wrapper-${numberOfTabs}`;

  return (
      <div data-tabs-text="TabContainer" className="tabs-container sc-tabs--with-text">
          {allowedEntryPointWithOutHome.map((entryPoint, i) => (
              <>
                  <Link onClick={() => { routeEventTrack('myarea_listing_creation', 'click', entryPoint.route); }} className={`${tabLabelCss} ${entryPoint.route === path ? 'active' : ''}`} to={entryPoint.route}>
                      <div className={`lc-tab-link sc-inline-link sc-tab sc-font-m ${entryPoint.route === path ? 'sc-tab--with-text--active' : 'inactive'}`} data-section={`Tab${i}`}>
                          <span className="tab-label-text">
                              {entryPoint.headerTranslation(translations)}
                          </span>
                      </div>
                  </Link>
                  <div className={`lc-tab-content sc-tabs__content ${entryPoint.route === path ? 'sc-tabs__content--visible' : ''}`} data-section={`Tab${i}`}>
                      {generateBody(entryPoint)}
                      {getVehicleList()}
                  </div>
              </>
          ))}
      </div>
  );
};

export const contentWrapperNoTabs = (entryPoint, className) => {
    return (
        <div className={`tab-content ${className}`}>
          {generateBody(entryPoint)}
          {getVehicleList()}
        </div>
    );
};
