import { connect } from 'react-redux';
import { changeAndUpdateLowerFields, getNextData, clearResults } from '../actions';
import DropDown from '../../../common/components/dropdown';
import { fieldNames } from '../../../common/hsnTsnConstants';
import { selectNumberDisabled } from '../../../common/defaultValues';

function mapStateToProps(state) {
  return {
    label: state.translations.hsntsn.data.doorsLabelSingular,
    name: fieldNames.doors,
    componentState: state.hsnTsnData.doors,
    defaultText: state.translations.hsntsn.data.pleaseChoose,
    options: state.hsnTsnData.doors.options,
    defaultValue: selectNumberDisabled.value
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onChange: (e) => {
      dispatch(changeAndUpdateLowerFields(parseInt(e.target.value, 10), fieldNames.doors));
      if (e.target.value !== 0) dispatch(getNextData(fieldNames.doors));
      dispatch(clearResults());
    }
  };
}
const Door = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDown);

export default Door;
