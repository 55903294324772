import { connect } from 'react-redux';
import {
  changeAndUpdateLowerFields, getNextData, clearResults, buttonReset, setColorParameter, fillManualLink
} from '../actions';
import DropdownWithIcon from './dropDownWithIcon';
import { fieldNames } from '../../../common/constants';

function mapStateToProps(state) {
  const pageName = state.configuration.translationsPageName.makemodel.pageName;
  return {
    label: state.translations[pageName].data.colorLabel,
    name: fieldNames.color,
    componentState: state.makeModelData.color,
    defaultText: state.translations[pageName].data.pleaseChoose,
    options: state.makeModelData.colors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange: (e) => {
      dispatch(changeAndUpdateLowerFields(parseInt(e.target.value, 10), fieldNames.color));
      if (e.target.value !== '0') {
        dispatch(getNextData(fieldNames.color));
        dispatch(fillManualLink());
      } else {
        dispatch(buttonReset());
      }
      dispatch(setColorParameter(parseInt(e.target.value, 10)));
      dispatch(clearResults());
    }
  };
}
const Color = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownWithIcon);

export default Color;
