import '@babel/polyfill';
import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import { syncHistoryWithStore } from 'react-router-redux';

import entryPointApp from './reducers';
import { routeLocationChange, trackEventsOnAppLoad } from './router/actions';
import Page from './page';
import { pageTrack, trackGA4PageView } from '../tracking/tracking';
import routes from './router/routes';
import { listenToElementPush } from '../helpers/arrayHelper';
import { getEntryPoints } from '../tabs/tabsFactory';
import { getEntryPointConfiguration } from './entryPointConfiguration';

require('../styles/main.scss');

const App = () => {
    const devTools = window.devToolsExtension ? window.devToolsExtension() : undefined;

    const configuration = getEntryPointConfiguration(
        window.userType,
        window.currentCulture,
        window.listingType,
    );

    const store = applyMiddleware(thunk)(createStore)(entryPointApp(configuration), devTools);

    const startingTab = () => {
        if (configuration.defaultTab !== routes.home) {
            return configuration.defaultTab;
        }
        return '';
    };

    const history = syncHistoryWithStore(createMemoryHistory({
        initialEntries: [startingTab()],
        initialIndex: 0
    }), store);

    history.push(startingTab());

    routeLocationChange(store, history);
    pageTrack();
    trackGA4PageView({ contentGroup: 'private_seller', contentID: 'manual_listing_creation', pageTitle: 'listing_creation_entry_point' });

    listenToElementPush(window.dataLayer,
        (element) => {
            return element.event && element.event === 'data_ready';
        },
        () => trackEventsOnAppLoad());

    return (
        <Provider store={store}>
            <div>
                <Router history={history}>
                    <Page entryPoints={getEntryPoints(configuration.allowedEntryPoints)} />
                </Router>
            </div>
        </Provider>
    );
};

export default App;
