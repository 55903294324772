import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Input from '../../../common/components/input';
import { fieldNames } from '../../../common/constants';
import { changeLicensePlateValue, setLicensePlateConfiguration } from '../action';

const onChange = dispatch => (e) => {
  dispatch(changeLicensePlateValue(e.target.value));
};

const InputLicensePlate = () => {
  const {
    translations, licensePlate, configuration
  } = useSelector(state => ({
    translations: state.translations,
    licensePlate: state.licensePlate,
    configuration: state.configuration.dynamicFields.licensePlate,
  }), shallowEqual);

  const dispatch = useDispatch();

  const licensePlateRegex = configuration.validateRegex;
  useEffect(() => {
    dispatch(setLicensePlateConfiguration(licensePlateRegex));
  }, [licensePlateRegex]);

  return (
    <Input
      name={fieldNames.licensePlate}
      className="license-plate-input sc-grid-row sc-grid-col-7 sc-grid-col-m-11 max-width-s lc-block-center sc-margin-top-xl"
      value={licensePlate.licensePlate.value}
      type="text"
      placeholder={translations.licensePlate.data.placeholder}
      label={translations.licensePlate.data.licensePlateLabel}
      maxLength={configuration.maxLength}
      isValid={licensePlate.licensePlate.isValid}
      isPristine={licensePlate.licensePlate.isPristine}
      errorMessage={translations.licensePlate.data.invalidInputErrorMessage}
      isHighlighted={licensePlate.licensePlate.isHighlighted}
      onChange={onChange(dispatch)}
    />
  );
};

export default InputLicensePlate;
