import {
  entryPointTypes,
  listingType,
  locale,
  nlLicensePlateMaxLength,
  itLicensePlateMaxLength,
  regexValidateLicensePlateItaly,
  regexValidateLicensePlateNederlands,
  userType
} from '../common/constants';
import {
  getVehicleType,
  isDealer,
  isCarForDealers,
  isCarDealersInGerman,
  isCarForCarSourcing,
  isCarForPrivates,
  isBikeForPrivates,
} from '../common/listingTypeUtils';
import routes from './router/routes';
import { translationParams } from '../translations/constants';

const getMakeUrl = receivedListingType => `/listing-creation-entry-point/lookups/makes/${getVehicleType(receivedListingType)}`;
const getModelsUrl = receivedListingType => `/listing-creation-entry-point/lookups/models/${getVehicleType(receivedListingType)}`;

const getPrivateCarUrl = (country, isManualLink) => (country === locale.deDE && !isManualLink
    ? '/auto-verkaufen/marktplatz/mini-form'
    : '/listing-form/index/private/car');

const getFormUrl = (receivedListingType, country, isManualLink = false) => {
  if (isCarForDealers(receivedListingType)) return '/listing-form#';
  if (isCarForCarSourcing(receivedListingType)) return '/listing-form/index/dealerSourcingCar#';
  if (isCarForPrivates(receivedListingType)) return getPrivateCarUrl(country, isManualLink);
  return '/listing-form/index/private/bike';
};

const getLicensePlateUrl = receivedUserType => (isDealer(receivedUserType)
        ? '/listing-creation-entry-point/license-plate/'
        : '/listing-creation-entry-point/license-plate/private/');

const allowedEntryPoints = {
  [listingType.carDealer]: {
    [locale.deAT]: [entryPointTypes.home, entryPointTypes.makemodel, entryPointTypes.natcode, entryPointTypes.vin],
    [locale.deDE]: [entryPointTypes.home, entryPointTypes.hsntsn, entryPointTypes.makemodel, entryPointTypes.vin],
    [locale.itIT]: [entryPointTypes.home, entryPointTypes.makemodel, entryPointTypes.licensePlate],
  },
  [listingType.carPrivate]: {
    [locale.itIT]: [entryPointTypes.home, entryPointTypes.carPrivate, entryPointTypes.licensePlate],
    [locale.deAT]: [entryPointTypes.home, entryPointTypes.carPrivate, entryPointTypes.natcode],
    [locale.nlNL]: [entryPointTypes.licensePlate],
    [locale.deDE]: [entryPointTypes.home, entryPointTypes.makemodel, entryPointTypes.hsntsn],
  }
};

const defaultEntryPoints = {
  [listingType.carDealer]: [entryPointTypes.home, entryPointTypes.makemodel],
  [listingType.bikePrivate]: [entryPointTypes.bikeprivate],
  [listingType.carSourcing]: [entryPointTypes.carSourcing],
  [listingType.carPrivate]: [entryPointTypes.home, entryPointTypes.carPrivate],
};

// When several entry points, home entry point is needed in order to have tabs and also have it as default entry point
const getAllowedEntryPoints = (receivedListingType, receivedCountry) => {
  const specificEntryPoints = allowedEntryPoints[receivedListingType];

  return specificEntryPoints !== undefined
      ? specificEntryPoints[receivedCountry] || defaultEntryPoints[receivedListingType]
      : defaultEntryPoints[receivedListingType];
};

const getURLConfiguration = (receivedUserType, receivedListingType, country) => {
  return {
    formUrl: getFormUrl(receivedListingType, country),
    manualUrl: getFormUrl(receivedListingType, country, true),
    makeUrl: getMakeUrl(receivedListingType),
    modelsUrl: getModelsUrl(receivedListingType),
    licensePlateUrl: getLicensePlateUrl(receivedUserType)
  };
};


const getTogglesConfiguration = (receivedUserType, country, receivedListingType) => {
  return {
    showDealerHeader: isDealer(receivedUserType),
    showBuildRange: isCarDealersInGerman(receivedListingType, country),
    showVehicleList: isDealer(receivedUserType) || isCarForPrivates(receivedListingType),
    showBuildDate: isDealer(receivedUserType),
    showBikePrivateBg: isBikeForPrivates(receivedListingType),
    showCarPrivateBgTabs: isCarForPrivates(receivedListingType),
    getColors: isDealer(receivedUserType) || isCarForPrivates(receivedListingType),
    getPrivateBikeTranslations: isBikeForPrivates(receivedListingType),
    getPrivateCarTranslations: isCarForPrivates(receivedListingType),
    shouldUseLocalStorage: isDealer(receivedUserType) && !isCarForCarSourcing(receivedListingType)
  };
};

const getVehiclePageName = receivedListingType => (receivedListingType === listingType.carSourcing
    ? translationParams.carSourcingVehicle
    : translationParams.vehicle);

const getMakeModelPageName = receivedListingType => (receivedListingType === listingType.carSourcing
    ? translationParams.carSourcingMakemodel
    : translationParams.makemodel);

const getTranslations = (receivedListingType) => {
  return {
    vehicle: {
      pageName: getVehiclePageName(receivedListingType)
    },
    makemodel: {
      pageName: getMakeModelPageName(receivedListingType)
    }
  };
};

const getTabLocationRule = (receivedUserType, country, receivedListingType) => {
  if (receivedUserType === userType.dealer && country === locale.itIT && receivedListingType === listingType.carDealer) {
    return location => (location && location.pathname !== routes.home ? location.pathname : routes.licensePlate);
  }

  return location => (location ? location.pathname : routes.home);
};

const getDefaultTab = (receivedListingType, country) => {
  if (receivedListingType !== listingType.bikePrivate && receivedListingType !== listingType.carSourcing && country === locale.itIT) return routes.licensePlate;
  if (receivedListingType === listingType.carPrivate && country === locale.nlNL) return routes.licensePlate;
  if (receivedListingType === listingType.carPrivate && (country === locale.deDE || country === locale.deAT)) return routes.makemodel;
  if (receivedListingType === listingType.carDealer && country === locale.deDE) return routes.makemodel;
  if (receivedListingType === listingType.carDealer && country === locale.deAT) return routes.natcode;
  return routes.home;
};

const getDynamicFieldsConfigurations = (receivedUserType, country) => {
  const fields = {};

  switch (country) {
    case locale.nlNL:
      return {
        licensePlate: {
          validateRegex: regexValidateLicensePlateNederlands,
          maxLength: nlLicensePlateMaxLength,
        }
      };
    case locale.itIT:
      return {
        licensePlate: {
          validateRegex: regexValidateLicensePlateItaly,
          maxLength: itLicensePlateMaxLength,
        }
      };
    default:
      return fields;
  }
};

export const getEntryPointConfiguration = (receivedUserType, country, receivedListingType) => ({
    toggles: getTogglesConfiguration(receivedUserType, country, receivedListingType),
    urls: getURLConfiguration(receivedUserType, receivedListingType, country),
    allowedEntryPoints: getAllowedEntryPoints(receivedListingType, country),
    tabLocationRule: getTabLocationRule(receivedUserType, country, receivedListingType),
    defaultTab: getDefaultTab(receivedListingType, country),
    translationsPageName: getTranslations(receivedListingType),
    dynamicFields: getDynamicFieldsConfigurations(receivedUserType, country)
  });
