const stringZero = '0';
export const stringEmpty = '';
export const stringUnderscoreSeparated = '0_0_0';

export const minYear = 100;
export const maxYear = 0;

const basicProps = {
  isValid: true,
  isPristine: true,
  isMandatory: false,
  isHidden: false
};

export const stringMandatory = {
  ...basicProps,
  isValid: false,
  isMandatory: true,
  value: '',
  maxLength: 0,
  numOfCharsLeft: 0
};

export const selectStringUnderscoreSeparated = {
  value: stringUnderscoreSeparated,
  options: [],
  isValid: false,
  isDisabled: true
};

export const selectString = {
  value: stringZero,
  options: [],
  isValid: false,
  isDisabled: false
};

export const selectStringHidden = {
  value: stringZero,
  options: [],
  isValid: false,
  isHidden: true
};

export const selectStringDisabled = {
  ...selectString,
  isDisabled: true
};

export const selectNumber = {
  value: 0,
  isHidden: false,
  isValid: false
};

export const selectNumberDisabled = {
  value: 0,
  options: [],
  isValid: false,
  isDisabled: true
};

export const selectNumberHidden = {
  value: 0,
  options: [],
  isValid: false,
  isHidden: true
};

export const firstReg = {
  month: stringZero,
  year: stringZero,
  isValid: false,
  isPristine: true,
  monthPristine: true,
  yearPristine: true,
  isDisabled: true
};
export const hsnTsn = {
  hsn: '',
  tsn: '',
  isValid: false,
  hsnValid: false,
  tsnValid: false,
  isPristine: true,
  hsnPristine: true,
  tsnPristine: true
};
