import React from 'react';
import { PropTypes } from 'prop-types';
import { Switch } from 'react-router-dom';
import Tabs from '../tabs/tabsContainer';
import PageHeader from '../pageheader/pageHeaderContainer';
import CountryNotification from '../common/components/countryNotification';

// TODO: refactor this children as props to entry points from state?
const Page = (props) => {
  const { entryPoints } = props;
  return (
    <div>
      {
        window.dealerCountry !== window.country ? <CountryNotification /> : null
      }
      <div id="errorContainer" />
      <PageHeader />

      <Tabs>
        <Switch>
          {entryPoints}
        </Switch>
      </Tabs>
    </div>
  );
};

Page.propTypes = { entryPoints: PropTypes.array };

export default Page;
