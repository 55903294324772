import * as actionTypes from './actionTypes';

export const fetchTranslations = (page) => {
  return {
    type: actionTypes.TRANSLATIONS_REQUEST,
    pageName: page
  };
};
export const translationSuccess = (page, data) => {
  return {
    type: actionTypes.TRANSLATIONS_SUCCESS,
    pageName: page,
    translations: data
  };
};
export const translationFailure = (page, error) => {
  return {
    type: actionTypes.TRANSLATIONS_FAILURE,
    pageName: page,
    error
  };
};

export const getTranslationsAsync = (page) => {
  return (dispatch, getState) => {
    const state = getState();
    if (Object.keys(state.translations[page].data).length > 0 || state.translations[page].isFetching) {
      return null;
    }
    dispatch(fetchTranslations(page));
    return fetch('/listing-creation-entry-point/translation/'.concat(page) + '/'.concat(window.currentCulture))
      .then(response => response.json())
      .then((json) => {
        dispatch(translationSuccess(page, json));
      })
      .catch((error) => {
        dispatch(translationFailure(page, error));
      });
  };
};
