import React from 'react';
import { PropTypes } from 'prop-types';

const Tooltip = ({ iconType, children }) => (
  <as24-tooltip class="sc-tooltip">
    <as24-icon class="tooltipIcon" type={iconType} />
    <div className="sc-tooltip-content tooltipContent">{children}</div>
  </as24-tooltip>
);

Tooltip.propTypes = {
  iconType: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Tooltip.defaultProps = {
  iconType: 'info',
};

export default Tooltip;
