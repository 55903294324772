import { connect } from 'react-redux';
import {
  changeAndUpdateLowerFields, getNextData, clearResults, showEquipmentLine
} from '../actions';
import DropDown from '../../../common/components/dropdown';
import { fieldNames } from '../../../common/hsnTsnConstants';

function mapStateToProps(state) {
  return {
    label: state.translations.hsntsn.data.powerLabel,
    name: fieldNames.power,
    componentState: state.hsnTsnData.power,
    defaultText: state.translations.hsntsn.data.pleaseChoose,
    options: state.hsnTsnData.power.options
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange: (e) => {
      dispatch(changeAndUpdateLowerFields(parseInt(e.target.value, 10), fieldNames.power));
      if (e.target.value !== '0') dispatch(getNextData(fieldNames.power));
      if (e.target.value !== '0') dispatch(showEquipmentLine());
      dispatch(clearResults());
    }
  };
}
const Power = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDown);

export default Power;
