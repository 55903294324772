import React from 'react';
import { PropTypes } from 'prop-types';
import routes from '../app/router/routes';
import { contentWrapperNoTabs, contentWrapperWithTabs, onlyOneEntryPoint } from './tabsElements';
import { listingType } from '../common/constants';

const isCampaignPeriod = () => {
    const currentDate = new Date();
    return currentDate >= new Date('2024-10-21') && currentDate <= new Date('2024-11-31');
};
const desktopCampaignImagePath = isCampaignPeriod() && window.country.toLowerCase() === 'de'
    ? '/assets/listing-creation-entry-point/images/hero/campaign-1100x440'
    : '/assets/listing-creation-entry-point/images/hero/car-1100x440';

const mobileCampaignImagePath = isCampaignPeriod() && window.country.toLowerCase() === 'de'
    ? '/assets/listing-creation-entry-point/images/hero/campaign-700x280'
    : '/assets/listing-creation-entry-point/images/hero/car-700x280';

export const Tabs = ({
 translations, path, children, showBikePrivateBg, showCarPrivateBgTabs
}) => {
  const allowedEntryPoints = children.props.children;
  const allowedEntryPointWithOutHome = allowedEntryPoints.filter(entryPoint => entryPoint.route !== routes.home);
  const { common } = translations;

  const bikePrivateClassName = showBikePrivateBg ? 'lc-bike-private' : '';

  const country = window.country.toLowerCase();
  const activeTab = path.split('/')[1];
  const carPrivateClassName = (country === 'de' || country === 'at') ? 'lc-header-car-private lc-header-car-private-de sc-font-bold sc-font-xxl' : 'lc-header-car-private sc-font-bold sc-font-xxl';
  const headlineRaw = window.isSSR && window.listingType === listingType.carPrivate ? window.translations.headline : translations.carPrivate.data.headline;
  const tempHeadlineRaw = country === 'de' && isCampaignPeriod ? 'Gib deine Fahrzeugdetails ein und sichere dir deine Teilnahme am Gewinnspiel' : headlineRaw;
  return (
    <div className={(showCarPrivateBgTabs ? 'lc-car-private' : '')}>
      {showCarPrivateBgTabs
        ? (
            <>
              <picture className="lc-car-private-header-img">
                <source
                  type="image/webp"
                  srcSet={`${mobileCampaignImagePath}.webp 700w, ${desktopCampaignImagePath}.webp 1100w`}
                  sizes="(min-width: 768px) 1100px, 100vw"
                />

                <img
                  src={`${mobileCampaignImagePath}.jpg`}
                  srcSet={`${mobileCampaignImagePath}.jpg 700w, ${desktopCampaignImagePath}.jpg 1100w`}
                  sizes="(min-width: 768px) 1100px, 100vw"
                  loading="eager"
                  decoding="async"
                  alt="Car"
                />
              </picture>

                 <h1 className={carPrivateClassName} dangerouslySetInnerHTML={{ __html: tempHeadlineRaw }} />
            </>
          )
        : null}
      <div
        className={`tabs ${showCarPrivateBgTabs 
          ? `lc-private-tabs lc-private-tabs-${country} lc-private-tabs-${activeTab}` 
          : ''
        }`
      }
      >
          {
              onlyOneEntryPoint(allowedEntryPointWithOutHome)
                  ? contentWrapperNoTabs(allowedEntryPoints[0], bikePrivateClassName)
                  : contentWrapperWithTabs(allowedEntryPointWithOutHome, common, path)
          }
      </div>
    </div>
  );
};

Tabs.propTypes = {
  translations: PropTypes.object,
  path: PropTypes.string,
  children: PropTypes.element.isRequired,
  showBikePrivateBg: PropTypes.bool,
  showCarPrivateBgTabs: PropTypes.bool
};
