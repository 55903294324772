import { entryPointTypes } from '../common/constants';
import routes from '../app/router/routes';
import MakeModel from '../modules/makemodel/container';
import HsnTsn from '../modules/hsntsn/container';
import NatCode from '../modules/natcode/container';
import Vin from '../modules/vin/vinContainer';
import BikePrivate from '../modules/bikeprivate/component';
import LicensePlate from '../modules/licensePlate/component';

const entryPoints = [
  {
    title: entryPointTypes.carPrivate,
    route: routes.makemodel,
    component: MakeModel,
    headerTranslation: translation => translation.data.withoutKeyNumber,
  },
  {
    title: entryPointTypes.bikeprivate,
    route: routes.home,
    component: BikePrivate,
    headerTranslation: () => '',
  },
  {
    title: entryPointTypes.home,
    route: routes.home,
    component: MakeModel,
    headerTranslation: translation => translation.data.withoutKeyNumber,
  },
  {
    title: entryPointTypes.carSourcing,
    route: routes.home,
    component: MakeModel,
    headerTranslation: translation => translation.data.withoutKeyNumber,
  },
  {
    title: entryPointTypes.makemodel,
    route: routes.makemodel,
    component: MakeModel,
    headerTranslation: translation => translation.data.withoutKeyNumber,
  },
  {
    title: entryPointTypes.vin,
    route: routes.vin,
    component: Vin,
    headerTranslation: translation => translation.data.vinLabel,
  },
  {
    title: entryPointTypes.natcode,
    route: routes.natcode,
    component: NatCode,
    headerTranslation: translation => translation.data.withKeyNumber,
  },
  {
    title: entryPointTypes.hsntsn,
    route: routes.hsntsn,
    component: HsnTsn,
    headerTranslation: translation => translation.data.withKeyNumber,
  },
  {
    title: entryPointTypes.licensePlate,
    route: routes.licensePlate,
    component: LicensePlate,
    headerTranslation: translation => translation.data.licensePlateLabel,
  },
];


export const getEntryPoints = (allowedEntryPoints) => {
  return entryPoints
    .filter(entryPoint => allowedEntryPoints.includes(entryPoint.title))
    .sort((a, b) => allowedEntryPoints.indexOf(a.title) - allowedEntryPoints.indexOf(b.title));
};
