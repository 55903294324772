import React from 'react';
import { PropTypes } from 'prop-types';
import Tooltip from './tooltip';

const InputGroup = ({
  buildRange, buildRangeText, firstRegistrationText, onClick, tooltipContent
}) => {
  return (
    <div className="sc-grid-row sc-absolute-center">
      <div className="sc-input-group-radio sc-grid-col-xl-8 sc-grid-col-l-8 sc-grid-col-m-8 sc-grid-col-s-12 sc-grid-col-xs-12 sc-margin-top-xl">
        <input className="sc-input" id="firstRegistration-lc" name="buildRange-lc" type="radio" onClick={() => onClick(false)} checked={!buildRange} />
        <label htmlFor="firstRegistration-lc">{firstRegistrationText}</label>
        <input className="sc-input" id="buildRange-lc" name="buildRange-lc" type="radio" onClick={() => onClick(true)} checked={buildRange} />
        <label htmlFor="buildRange-lc">{buildRangeText}</label>
        <div className="lc-margin-top-s lc-margin-left-xs">
          <Tooltip>{tooltipContent()}</Tooltip>
        </div>
      </div>
    </div>
  );
};

InputGroup.propTypes = {
  buildRange: PropTypes.bool,
  buildRangeText: PropTypes.string,
  firstRegistrationText: PropTypes.string,
  onClick: PropTypes.func,
  tooltipContent: PropTypes.func,
};
InputGroup.defaultProps = {
  buildRange: false,
  buildRangeText: '',
  firstRegistrationText: '',
};

export default InputGroup;
