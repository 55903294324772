import {
  GET_VEHICLES_SUCCESS, CLEAR_VEHICLES_RESULT, SET_COLOR_PARAMETER, SET_VEHICLE_OFFER_TYPE_PARAMETER, FILL_MANUAL_LINK
} from '../app/actionTypes';

export const initialState = {
  vehicles: [],
  manualLink: '',
  color: 0,
  vehicleOfferType: 'U'
};

const clearFirstReg = (item) => {
  const mthIndex = item.indexOf('firstreg_mth');
  const mthAmpIndex = item.indexOf('&', mthIndex);
  const yearIndex = item.indexOf('firstreg_year');
  const yearAmpIndex = item.indexOf('&', yearIndex);

  return item.replace(item.substring(mthIndex, mthAmpIndex + 1), '').replace(item.substring(yearIndex, yearAmpIndex + 1), '');
};

const vehicleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VEHICLES_SUCCESS: {
      const vehicles = action.isBuildRange ? action.vehicles.map((item) => {
        return {
          ...item,
          url: clearFirstReg(item.url)
        };
      }) : action.vehicles;

      return { ...state, vehicles };
    }
    case CLEAR_VEHICLES_RESULT:
      return { ...state, vehicles: [] };
    case SET_COLOR_PARAMETER:
      return { ...state, color: action.value };
    case SET_VEHICLE_OFFER_TYPE_PARAMETER:
      return { ...state, vehicleOfferType: action.value };
    case FILL_MANUAL_LINK:
      return { ...state, manualLink: action.url };
    default:
      return state;
  }
};
export default vehicleListReducer;
