import { connect } from 'react-redux';
import InputGroup from '../../../common/components/inputGroup';
import { changeBuildRange, enableFirstReg, clearResults } from '../actions';

function mapStateToProps(state) {
  return {
    buildRange: state.natCode.buildRange,
    buildRangeText: state.translations.natCode.data.buildRange,
    firstRegistrationText: state.translations.natCode.data.firstRegistration,
    tooltipContent: () => state.translations.natCode.data.buildYearTooltip,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: (val) => {
      dispatch(changeBuildRange(val));
      dispatch(enableFirstReg());
      dispatch(clearResults());
    }
  };
}
const BuildDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(InputGroup);

export default BuildDate;
