import React from 'react';
import { PropTypes } from 'prop-types';
import VehicleItem from './vehicleItemComponent';
import { featureEnabled } from '../helpers/features';
import { eventTrack, trackEventGA4 } from '../tracking/tracking';

function trackManualLinkClick() {
    eventTrack('myarea_listing_creation', 'listing_manual');
    trackEventGA4('listing_creation_select', 'action', 'manual');
    return true;
}

function makeManualLink(translations, manualUrl) {
    return translations.common.data.listingFormBaseUrl + manualUrl;
}

const VehicleList = ({
  vehicles, translations, route, colorParameter, vehicleOfferTypeParameter, pageName, listingFormUrl, manualUrl
}) => {
  const wrap = content => (<div id="vehiclelistheader">{content}</div>);
  let vehicleHeadline;
  let vehicleList;
  const hideManualCreationLink = (route === '/vin' && featureEnabled('datVinServiceTemporary'));
  if (vehicles.length !== 0) {
    vehicleHeadline = (
      <div className="sc-grid-row">
        <div className="sc-grid-col-2 sc-hidden-m" />
        <div className="sc-grid-col-8 sc-grid-col-m-12 sc-grid-col-s-12">
          <h2
            className="vehicle-selection-title sc-text-center sc-font lc-padding-top-xxxl lc-padding-bottom-xxl lc-padding-horizontal-l"
          >
            {vehicles.length === 1 ? translations[pageName].data.singleVehicleHeadline : translations.vehicle.data.multipleVehicleHeadLine}
          </h2>
        </div>
      </div>
    );
    vehicleList = vehicles.map((vehicle, index) => {
      return (
        <VehicleItem
          vehicle={vehicle}
          translations={translations}
          route={route}
          colorParameter={colorParameter}
          vehicleOfferTypeParameter={vehicleOfferTypeParameter}
          index={index}
          listingFormUrl={listingFormUrl}
          pageName={pageName}
        />
      );
    });
  }

  const href = makeManualLink(translations, manualUrl);
  return wrap(
    <div id="vehicle-selection">
      {vehicleHeadline}
      {vehicleList}
      <div className={hideManualCreationLink ? 'sc-hidden' : 'sc-grid-row'}>
        <div
          id="create-manually"
          className={`lc-create-manually sc-text-center sc-grid-col-12${hideManualCreationLink && ' sc-hidden'}`}
        >
          <p>{translations[pageName].data.listManuallyTitle}</p>
          <a id="manual-link" href={href} onClick={trackManualLinkClick}>
              {translations[pageName].data.listManuallyLink}
          </a>
        </div>
      </div>
    </div>
  );
};

VehicleList.propTypes = {
  vehicles: PropTypes.arrayOf(
    PropTypes.shape(
      {
        make: PropTypes.string,
        model: PropTypes.string,
        version: PropTypes.string,
        fuelType: PropTypes.string,
        powerKw: PropTypes.number,
        powerPs: PropTypes.number,
        gearType: PropTypes.string,
        seats: PropTypes.number,
        bodyType: PropTypes.string,
        gears: PropTypes.string,
        doors: PropTypes.number
      }
    ).isRequired
  ).isRequired,
  translations: PropTypes.object.isRequired,
  route: PropTypes.string.isRequired,
  colorParameter: PropTypes.number.isRequired,
  manualLink: PropTypes.string
};

export default VehicleList;
