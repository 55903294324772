import { connect } from 'react-redux';
import {
  changeAndUpdateLowerFields, getNextData, clearResults, setColorParameter
} from '../actions';
import DropdownWithIcon from './dropDownWithIcon';
import { fieldNames } from '../../../common/hsnTsnConstants';

function mapStateToProps(state) {
  return {
    label: state.translations.hsntsn.data.colorLabel,
    name: 'color',
    componentState: state.hsnTsnData.color,
    defaultText: state.translations.hsntsn.data.pleaseChoose,
    options: state.hsnTsnData.colors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange: (e) => {
      dispatch(changeAndUpdateLowerFields(parseInt(e.target.value, 10), fieldNames.color));
      if (e.target.value !== '0') dispatch(getNextData(fieldNames.color));
      dispatch(setColorParameter(parseInt(e.target.value, 10)));
      dispatch(clearResults());
    }
  };
}
const Color = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownWithIcon);

export default Color;
