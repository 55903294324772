import { connect } from 'react-redux';
import { changeAndUpdateLowerFields, getNextData, clearResults } from '../actions';
import DropDown from '../../../common/components/dropdown';
import { fieldNames } from '../../../common/hsnTsnConstants';

function mapStateToProps(state) {
  return {
    label: state.translations.hsntsn.data.fuelLabel,
    name: fieldNames.fuel,
    componentState: state.hsnTsnData.fuel,
    defaultText: state.translations.hsntsn.data.pleaseChoose,
    options: state.hsnTsnData.fuel.options
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange: (e) => {
      dispatch(changeAndUpdateLowerFields(e.target.value, fieldNames.fuel));
      if (e.target.value !== '0') dispatch(getNextData(fieldNames.fuel));
      dispatch(clearResults());
    }
  };
}
const Fuel = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDown);

export default Fuel;
