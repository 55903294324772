import React from 'react';
import { PropTypes } from 'prop-types';

const Dropdown = ({
  label, name, componentState, defaultText, onChange, options, children, defaultValue = '0', styles = ''
}) => (
  <div className={`lc-component select-field ${(componentState.isHidden ? 'sc-hidden' : '')} ${styles}`}>
    <span className={`lc-dropdown-label ${(componentState.isDisabled ? 'sc-font-silent' : '')}`}>{label}</span>
    <select
      className="sc-input"
      name={name}
      value={componentState.value}
      onChange={onChange}
      disabled={componentState.isDisabled}
      data-valid={componentState.isValid || componentState.isPristine}
    >
      {defaultText !== undefined ? <option className="lc-padding-left-l" value={defaultValue}>{defaultText}</option> : null}
      {children !== undefined ? children : null}
      {options !== undefined ? (options.map((option) => {
        return <option value={option.id} key={option.id} className="lc-padding-left-l">{option.label}</option>;
      })) : null}
    </select>
  </div>
);

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  componentState: PropTypes.object.isRequired,
  defaultText: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  children: PropTypes.node,
  styles: PropTypes.string,
};

export default Dropdown;
