import { connect } from 'react-redux';
import { Tabs } from './tabs';

function mapStateToProps(state) {
  const pathname = state.routing.locationBeforeTransitions
    ? state.routing.locationBeforeTransitions.pathname
    : '/makemodel';
  return {
    translations: state.translations,
    path: pathname,
    showBikePrivateBg: state.configuration.toggles.showBikePrivateBg,
    showCarPrivateBgTabs: state.configuration.toggles.showCarPrivateBgTabs
  };
}

export default connect(mapStateToProps)(Tabs);
