export const CHANGE_AND_UPDATE_LOWER_FIELDS_HSNTSN = 'CHANGE_AND_UPDATE_LOWER_FIELDS_HSNTSN';
export const TAXONOMY_SUCCESS_HSNTSN = 'TAXONOMY_SUCCESS_HSNTSN';
export const TAXONOMY_RESULT_SUCCESS_HSNTSN = 'TAXONOMY_RESULT_SUCCESS_HSNTSN';
export const TAXONOMY_FAILURE_HSNTSN = 'TAXONOMY_FAILURE_HSNTSN';
export const SHOW_FIRST_REG_HSNTSN = 'SHOW_FIRST_REG_HSNTSN';
export const SHOW_COLOR_HSNTSN = 'SHOW_COLOR_HSNTSN';
export const SHOW_EQUIPMENT_LINE_HSNTSN = 'SHOW_EQUIPMENT_LINE_HSNTSN';
export const CHANGE_FIRST_REG_AND_UPDATE_LOWER_FIELDS_HSNTSN = 'CHANGE_FIRST_REG_AND_UPDATE_LOWER_FIELDS_HSNTSN';
export const CHANGE_HSN_TSN_AND_UPDATE_LOWER_FIELDS_HSNTSN = 'CHANGE_HSN_TSN_AND_UPDATE_LOWER_FIELDS_HSNTSN';
export const COLORS_REQUEST_HSNTSN = 'COLORS_REQUEST_HSNTSN';
export const COLORS_SUCCESS_HSNTSN = 'COLORS_SUCCESS_HSNTSN';
export const COLORS_FAILURE_HSNTSN = 'COLORS_FAILURE_HSNTSN';
export const VEHICLE_OFFER_TYPES_REQUEST_HSNTSN = 'VEHICLE_OFFER_TYPES_REQUEST_HSNTSN';
export const VEHICLE_OFFER_TYPES_SUCCESS_HSNTSN = 'VEHICLE_OFFER_TYPES_SUCCESS_HSNTSN';
export const VEHICLE_OFFER_TYPES_FAILURE_HSNTSN = 'VEHICLE_OFFER_TYPES_FAILURE_HSNTSN';
export const CHANGE_VEHICLE_OFFER_TYPE = 'CHANGE_VEHICLE_OFFER_TYPE';
export const FIRST_REG_CHANGE = 'FIRST_REG_CHANGE';
export const FIRST_REG_CHANGE_VALIDATION = 'FIRST_REG_CHANGE_VALIDATION';
export const SHOW_FIRST_REG_HSN_TSN = 'SHOW_FIRST_REG_HSN_TSN';
export const UPDATE_LOWER_FIELDS_HSNTSN = 'UPDATE_LOWER_FIELDS_HSNTSN';
export const CHANGE_BUILD_RANGE_HSNTSN = 'CHANGE_BUILD_RANGE_HSNTSN';
