import { locale } from '../common/constants';

export const emptyStringToNull = (value) => {
  if (!value || value === '0') {
    return null;
  }
  return value;
};

export const emptyString = (value) => {
  if (value === '0') {
    return null;
  } if (!value) {
    return '';
  }
  return value;
};

export const emptyIntToNull = (value) => {
  if (!value || value === 0) {
    return null;
  }
  return value;
};
export const emptyIntArrayToNull = (stringArray, index) => {
  const array = stringArray.split('_');
  const value = parseInt(array[index], 10);
  return emptyIntToNull(value);
};

const createParam = (param, value) => {
  if (parseInt(value, 10) !== 0 && value !== null) {
    return param + value;
  }
  return '';
};

const getDomain = (value) => {
  switch (value) {
    case locale.deAT:
      return 'at';
    case locale.deDE:
      return 'de';
    case locale.esES:
      return 'es';
    case locale.frFR:
      return 'fr';
    case locale.frBE:
    case locale.nlBE:
      return 'be';
    case locale.frLU:
      return 'lu';
    case locale.itIT:
      return 'it';
    case locale.nlNL:
      return 'nl';
    default:
      return 'com';
  }
};

function getValue(field, valueProperty = 'value') {
  return field ? field[valueProperty] : null;
}

export const getCustomUrl = (entryPointState, path) => {
  const domain = getDomain(window.currentCulture);
  const url = 'https://www.autoscout24.'.concat(domain).concat(path);
  return url
    .concat('?')
    .concat(createParam('make=', getValue(entryPointState.make)))
    .concat(createParam('&model=', emptyIntArrayToNull(getValue(entryPointState.model), 0)))
    .concat(createParam('&fuel=', getValue(entryPointState.fuel)))
    .concat(createParam('&color=', getValue(entryPointState.color)))
    .concat(createParam('&kw=', getValue(entryPointState.power)))
    .concat(!entryPointState.buildRange ? createParam('&firstreg_mth=', getValue(entryPointState.firstRegistration, 'month')) : '')
    .concat(!entryPointState.buildRange ? createParam('&firstreg_year=', getValue(entryPointState.firstRegistration, 'year')) : '')
    .concat(createParam('&vehicleOfferTypeId=', entryPointState.vehicleOfferType !== undefined ? entryPointState.vehicleOfferType.value : 'U'));
};
