import React from 'react';
import { PropTypes } from 'prop-types';

const Input = ({
  name,
  value,
  label,
  type,
  placeholder,
  unitLabel,
  minLength = 0,
  maxLength,
  isValid = true,
  isPristine = false,
  isDisabled = false,
  isHidden = false,
  isHighlighted = false,
  errorMessage = '',
  onChange,
  className = ''
}) => (
  <div className={isHidden ? 'sc-hidden' : `lc-component lf-movable text-input ${className}`}>
    <span className="lc-input-label">{label}</span>
    <div className="number-input-container">
      <input
        type={type}
        className="sc-input"
        name={name}
        value={value}
        minLength={minLength}
        maxLength={maxLength}
        onChange={onChange}
        placeholder={placeholder}
        data-valid={(isValid || isPristine) && !isHighlighted}
        disabled={isDisabled}
      />
      {(unitLabel) ? (
        <span className="unit">{unitLabel}</span>
      )
        : null
      }
    </div>
    {(!isValid && !isPristine && !isDisabled) ? (
      <span className="error sc-font-s">{errorMessage}</span>
    )
      : null}
  </div>
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  unitLabel: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  errorMessage: PropTypes.string,
  isValid: PropTypes.bool,
  isPristine: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isHidden: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isHighlighted: PropTypes.bool,
};

export default Input;
