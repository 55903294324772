const routes = {
  home: '/',
  vin: '/vin',
  makemodel: '/makemodel',
  hsntsn: '/hsntsn',
  natcode: '/natcode',
  licensePlate: '/licensePlate'
};

export default routes;
