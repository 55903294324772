import {
  listingType, locale, userType, vehicleName, vehicleType
} from './constants';

export const getVehicleName = (receivedListingType) => {
  return receivedListingType.includes(vehicleName.bike) ? 'moto' : vehicleName.car;
};


export const getVehicleType = (receivedListingType) => {
  return receivedListingType.includes(vehicleName.bike) ? vehicleType.bike : vehicleType.car;
};

export const getUserType = (receivedListingType) => {
  const vehicleTypePerListingType = {
    [listingType.carDealer]: userType.dealer,
    [listingType.bikePrivate]: userType.private,
  };
  return vehicleTypePerListingType[receivedListingType] || userType.dealer;
};

export const isDealer = receivedUserType => receivedUserType === userType.dealer;

export const isCarForDealers = receivedListingType => receivedListingType === listingType.carDealer;
export const isCarForPrivates = receivedListingType => receivedListingType === listingType.carPrivate;
export const isBikeForPrivates = receivedListingType => receivedListingType === listingType.bikePrivate;

export const isCarForCarSourcing = receivedListingType => receivedListingType === listingType.carSourcing;

export const isCarDealersInGerman = (receivedListingType, country) => {
  return isCarForDealers(receivedListingType) && country === locale.deDE;
};

export const camelToKebabCase = str => str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
