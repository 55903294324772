import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { saveVehicleData } from '@autoscout24/as24-vehicle-data-local';
import { eventTrackingValuesByPath } from '../../../tracking/tracking';
import { getCustomUrl } from '../../../helpers/paramsHelper';

const NoTaxonomyButton = ({
 text, manualUrl, show, trackingParameter, vehicleData
}) => {
  const handleClick = () => {
    saveVehicleData('MLC', vehicleData);
  };
  return show && (
    <div className={show ? 'lc-component button-block' : 'sc-hidden'}>
      <a href={manualUrl.concat(`&lct=${trackingParameter}`)} className="sc-btn-bob sc-btn-block" onClick={handleClick}>
        {text}
      </a>
    </div>
  );
};
NoTaxonomyButton.propTypes = {
  text: PropTypes.string.isRequired,
  manualUrl: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  trackingParameter: PropTypes.string.isRequired,
  vehicleData: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const pageName = state.configuration.translationsPageName.makemodel.pageName;
  const pathname = state.routing.locationBeforeTransitions
    ? state.routing.locationBeforeTransitions.pathname
    : '/makemodel';

  return {
    show: state.makeModelData.redirectButton.show,
    text: state.translations[pageName].data.buttonText,
    manualUrl: getCustomUrl(state.makeModelData, state.configuration.urls.manualUrl),
    trackingParameter: eventTrackingValuesByPath[pathname],
    vehicleData: state.makeModelData
  };
}
const RedirectButton = connect(mapStateToProps)(NoTaxonomyButton);

export default RedirectButton;
