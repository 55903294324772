import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { datSupportedMakesLightBoxId } from '../../../common/constants';

import DatSupportedMakes from '../datMakes';

const legalTextMakes = ['Opel', 'Smart'];

const makeRow = (mk, legalText) => {
  if (!legalText) {
    return (
      <tr>
        <td>{mk.make}</td>
        <td>{mk.year}</td>
      </tr>
    );
  }
  const make = legalTextMakes.includes(mk.make) ? (
    <td>
      {mk.make}
      <sup>*</sup>
    </td>
  ) : <td>{mk.make}</td>;
  return (
    <tr>
      <td>{make}</td>
      <td>{mk.year}</td>
    </tr>
  );
};

const makeHeader = (makeText, constructionYearText) => {
  return (
    <thead>
      <th>{makeText}</th>
      <th>{constructionYearText}</th>
    </thead>
  );
};

const DatSupportedMakesBoxComponent = ({
  headline, makeText, constructionYearText, personalText, transporterText, supportedMakesList, legalText
}) => {
  return (
    <as24-lightbox className="sc-lightbox" id={datSupportedMakesLightBoxId}>
      <div className="sc-lightbox__container">
        <button className="sc-lightbox__close" data-lightbox-close data-test="icon">
          <as24-icon type="close" />
        </button>
        <div className="sc-lightbox__content">
          <h5 className="sc-font-l sc-font-bold">{headline}</h5>
          <br />
          <p className="sc-font-m sc-font-bold lc-padding-bottom-s">{personalText}</p>
          <div className="sc-font-m">
            <table className="lc-supported-makes-table">
              {makeHeader(makeText, constructionYearText)}
              <tbody>
                {supportedMakesList.personal.map(mk => makeRow(mk, true))}
              </tbody>
            </table>
          </div>
          <div className="sc-font-s sc-font-silent lc-padding-top-s">
            <sup>*</sup>
            {' '}
            {legalText}
          </div>
          <p className="sc-font-m sc-font-bold lc-padding-top-l lc-padding-bottom-s">{transporterText}</p>
          <table className="lc-supported-makes-table">
            {makeHeader(makeText, constructionYearText)}
            <tbody>
              {supportedMakesList.transporters.map(mk => makeRow(mk, false))}
            </tbody>
          </table>
        </div>
      </div>
    </as24-lightbox>
  );
};

const makeYearShape = PropTypes.shape({
  make: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired
});

DatSupportedMakesBoxComponent.propTypes = {
  headline: PropTypes.string.isRequired,
  makeText: PropTypes.string.isRequired,
  constructionYearText: PropTypes.string.isRequired,
  personalText: PropTypes.string.isRequired,
  transporterText: PropTypes.string.isRequired,
  legalText: PropTypes.string.isRequired,
  supportedMakesList: PropTypes.shape({
    personal: PropTypes.arrayOf(makeYearShape.isRequired).isRequired,
    transporter: PropTypes.arrayOf(makeYearShape.isRequired).isRequired
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    headline: state.translations.vinform.data.datSupportedMakesBoxHeadline,
    makeText: state.translations.vinform.data.datSupportedMakesBoxMakeText,
    constructionYearText: state.translations.vinform.data.datSupportedMakesBoxConstructionText,
    personalText: state.translations.vinform.data.datSupportedMakesBoxPersonal,
    transporterText: state.translations.vinform.data.datSupportedMakesBoxTransporter,
    legalText: state.translations.vinform.data.datSupportedMakesBoxLegalText,
    supportedMakesList: DatSupportedMakes,
  };
}

const SupportedMakesBox = connect(
  mapStateToProps
)(DatSupportedMakesBoxComponent);

export default SupportedMakesBox;
