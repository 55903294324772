import {
  taxonomySuccess, taxonomyResultSuccess, taxonomyFailure, getNextData, showColor
} from '../hsntsn/actions';
import { fieldNames, taxonomyStatus } from '../../common/hsnTsnConstants';
import * as paramHelper from '../../helpers/paramsHelper';
import { globalTechnicalErrorNotification } from '../../notifications/helpers';

const getData = params => fetch(window.trackingConfiguration.environment === 'live'
  ? 'https://listing-creation-entry-point.a.autoscout24.com/listing-creation-entry-point/taxonomy'
  : '/listing-creation-entry-point/taxonomy',
{
  credentials: 'same-origin',
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'csrf-token': window.csrfToken
  },
  body: JSON.stringify(params)
})
  .then(response => response.json());

const createFilterParams = (state, idxNext) => {
  return {
    makeId: paramHelper.emptyStringToNull(''),
    month: paramHelper.emptyStringToNull(state.hsnTsnData.firstRegistration.month),
    year: paramHelper.emptyStringToNull(state.hsnTsnData.firstRegistration.year),
    hsn: paramHelper.emptyStringToNull(state.hsnTsnData.hsnTsn.hsn),
    tsn: paramHelper.emptyStringToNull(state.hsnTsnData.hsnTsn.tsn),
    isBuildRange: state.hsnTsnData.buildRange,
    modelId: paramHelper.emptyIntToNull(0),
    bodyType: paramHelper.emptyStringToNull(''),
    doors: paramHelper.emptyIntToNull(state.hsnTsnData.doors.value),
    fuelType: paramHelper.emptyStringToNull(state.hsnTsnData.fuel.value),
    powerKw: paramHelper.emptyIntToNull(state.hsnTsnData.power.value),
    equipmentLine: paramHelper.emptyStringToNull(state.hsnTsnData.equipmentLine.value),
    nextField: idxNext,
    culture: window.currentCulture,
  };
};

export const getNextTaxonomyData = (field, state, getTaxonomyData = getData, filterParams = createFilterParams) => (dispatch) => {
  return getTaxonomyData(filterParams(state, field))
    .then((json) => {
      if (field === fieldNames.result) {
        dispatch(taxonomyResultSuccess(json.data, state.hsnTsnData.buildRange));
      } else {
        switch (json.status) {
          case taxonomyStatus.valid:
            dispatch(taxonomySuccess(json.data, field));
            if (json.data.length === 1) {
              dispatch(getNextData(field));
              if (field === fieldNames.equipmentLine) {
                dispatch(showColor());
              }
            }
            break;
          case taxonomyStatus.skip:
            globalTechnicalErrorNotification(dispatch, json.data.errorMessage, json.data.errorLinkText);
            break;
          case taxonomyStatus.error:
            dispatch(taxonomyFailure(json.error));
            break;
          default:
            dispatch(taxonomySuccess(json.data, field));
            break;
        }
      }
    })
    .catch((error) => {
      dispatch(taxonomyFailure(error));
    });
};
