import React from 'react';
import { PropTypes } from 'prop-types';
import Make from './containers/make';
import Model from './containers/model';
import Fuel from './containers/fuel';
import Color from './elements/color';
import FirstRegistration from './containers/firstRegistration';
import Power from './containers/power';
import EquipmentLine from './containers/equipmentLine';
import RedirectButton from './elements/buttonRedirect';
import BuildDate from './containers/buildDate';
import { topMakeModel } from '../common/topMakeModel';

const MakeModelData = ({
 makeModelData, translations, showBuildDate, getPrivateCarTranslations, showCarPrivateBgTabs
}) => {
  const formDivClass = `sc-clearfix sc-block-center sc-padding-bottom-xxl sc-grid-col-s-12 sc-grid-row ${(showCarPrivateBgTabs
      ? 'lc-padding-horizontal-xxxl sc-grid-col-12'
      : 'sc-padding-horizontal-l sc-grid-col-6')}`;

  return (
    <div className="sc-grid-row">
      <div className="sc-grid-col-3 sc-hidden-s" />
      <div className={formDivClass}>
        {getPrivateCarTranslations
            ? <h2 className="lc-header sc-font-bold sc-font-m lc-padding-top-l lc-padding-bottom-l">{translations.carPrivate.data.description}</h2>
            : null}
        <Make>
          {topMakeModel(makeModelData)}
        </Make>
        {showBuildDate ? <BuildDate /> : null}
        <FirstRegistration />
        <Model />
        <Fuel />
        <Power />
        <EquipmentLine />
        <Color />
        <RedirectButton />
      </div>
    </div>
  );
};

MakeModelData.propTypes = {
  makeModelData: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  showBuildDate: PropTypes.bool,
  getPrivateCarTranslations: PropTypes.bool,
  showCarPrivateBgTabs: PropTypes.bool
};

export default MakeModelData;
