/* global platform */
export const ErrorNames = {
  licensePlateError: 'LicensePlateError',
};

export const LicensePlateError = (message, additionalData = {}) => {
  return {
    message,
    name: ErrorNames.licensePlateError,
    ...additionalData,
  };
};

export const publishError = (error) => {
  const errorBody = {
    type: 'caught',
    platform,
    error,
  };

  return fetch('/listing-creation-entry-point/log', {
    credentials: 'same-origin',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'csrf-token': window.csrfToken,
      customerId: window.customerId,
      guid: window.guid || 'noGuid',
      browserName: platform.name,
      userType: window.userType
    },
    body: JSON.stringify(errorBody)
  });
};
