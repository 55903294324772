import * as constants from './constants';

export const simpleSelectValidation = (value) => {
  return {
    value,
    isValid: value !== '0',
    isPristine: false
  };
};
export const htmlTagUrlEmailValidation = (value) => {
  return {
    value,
    isValid: (!constants.regexEmail.test(value) && !constants.regexTags.test(value) && !constants.regexUrl.test(value)),
    isPristine: false
  };
};
export const htmlTagUrlValidation = (value) => {
  return {
    value,
    isValid: (!constants.regexTags.test(value) && !constants.regexUrl.test(value)),
    isPristine: false
  };
};
export const pristineMandatory = (value) => {
  return {
    value,
    isValid: false,
    isPristine: true
  };
};

export const pristineNonMandatory = (value) => {
  return {
    value,
    isValid: true,
    isPristine: true
  };
};

export const checkIfValueIsInteger = (value) => {
  // trim whitespaces from either sides
  const val = value.toString().trim();

  // if val is just digits return true
  if (val.match(/^\d+$/)) {
    return true;
  }

  // if val is empty return false
  if (val === '') {
    return false;
  }
  // eslint requirement
  return false;
};

export const firstRegistrationValidation = (state, value) => {
  const tmpValid = state.firstRegistrationValidation;
  switch (value) {
    case 'J':
      tmpValid.vehicleType = value;
      tmpValid.minYear = 2;
      tmpValid.maxYear = 0;
      tmpValid.mandatory = true;
      tmpValid.visible = true;
      break;
    case 'O':
      tmpValid.vehicleType = value;
      tmpValid.minYear = 100;
      tmpValid.maxYear = -30;
      tmpValid.mandatory = true;
      tmpValid.visible = true;
      break;
    case 'S':
      tmpValid.vehicleType = value;
      tmpValid.minYear = 1;
      tmpValid.maxYear = 0;
      tmpValid.mandatory = true;
      tmpValid.visible = true;
      break;
    case 'U':
      tmpValid.vehicleType = value;
      tmpValid.minYear = 100;
      tmpValid.maxYear = 0;
      tmpValid.mandatory = true;
      tmpValid.visible = true;
      break;
    case 'D':
      tmpValid.vehicleType = value;
      tmpValid.minYear = 100;
      tmpValid.maxYear = 0;
      tmpValid.mandatory = false;
      tmpValid.visible = true;
      break;
    case 'N':
      tmpValid.vehicleType = value;
      tmpValid.minYear = 1;
      tmpValid.maxYear = 0;
      tmpValid.mandatory = false;
      tmpValid.visible = true;
      break;
    default:
      break;
  }
  return tmpValid;
};

const monthDiff = (date, now) => {
  let months = (now.getMonth() - date.getMonth()) + (12 * (now.getFullYear() - date.getFullYear()));

  if (now.getDate() < date.getDate()) {
    months -= 1;
  }
  return months;
};

const vehicleTypeLimit = (date, vehicleOfferType) => {
  const now = new Date();
  if (now.getTime() < date.getTime()) return false;
  const numOfMth = monthDiff(date, now);
  switch (vehicleOfferType) {
    case 'J':
      return numOfMth <= 24;
    case 'O':
      return numOfMth >= 360;
    case 'S':
      return numOfMth <= 12;
    default:
      return true;
  }
};

const firstRegInLimit = (state, month, year) => {
  if (month !== '0' && year !== '0') {
    const date = new Date(year, month - 1);
    return vehicleTypeLimit(date, state.firstRegistrationValidation.vehicleType);
  }
  return true;
};

const checkIfFirstRegistrationIsValid = (state, month, year, limit) => (state.firstRegistrationValidation.mandatory && month !== '0' && year !== '0' && limit)
|| (!state.firstRegistrationValidation.mandatory && !state.firstRegistrationValidation.visible && month === '0' && year === '0')
|| (!state.firstRegistrationValidation.mandatory && state.firstRegistrationValidation.visible);

export const firstRegistrationMonthAndYear = (state, value, otherField) => {
  const fieldChange = otherField !== undefined;
  const month = (fieldChange && otherField === 'year') ? value : state.firstRegistrationMonthAndYear.month;
  const year = (fieldChange && otherField === 'month') ? value : state.firstRegistrationMonthAndYear.year;

  const monthPristine = !fieldChange || (fieldChange && otherField === 'year') ? false : state.firstRegistrationMonthAndYear.monthPristine;
  const yearPristine = !fieldChange || (fieldChange && otherField === 'month') ? false : state.firstRegistrationMonthAndYear.yearPristine;

  const limitIsOk = firstRegInLimit(state, month, year);

  return {
    month,
    year,
    monthPristine,
    yearPristine,
    isPristine: monthPristine || yearPristine,
    limitIsOk,
    isValid: checkIfFirstRegistrationIsValid(state, month, year, limitIsOk),
  };
};

export const firstRegistrationClear = () => ({
  month: '0',
  year: '0',
  monthPristine: true,
  yearPristine: true,
  isPristine: true,
  limitIsOk: true,
  isValid: true,
});
