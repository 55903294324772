import React from 'react';

import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import Make from './containers/make';
import Model from './containers/model';
import Button from '../../common/components/button';
import { topMakeModel } from '../common/topMakeModel';
import { touchAllFields } from './actions';
import { getCustomUrl } from '../../helpers/paramsHelper';

const mobileHeroImagePath = '/assets/listing-creation-entry-point/images/hero/bike-700x274';
const desktopHeroImagePath = '/assets/listing-creation-entry-point/images/hero/bike-1100x430';

const onClick = (dispatch, bikePrivate, configuration) => (e) => {
  e.preventDefault();
  touchAllFields(dispatch)
    .then(() => {
      if (bikePrivate.make.isValid && bikePrivate.model.isValid) {
        window.location.href = getCustomUrl(bikePrivate, configuration.urls.formUrl);
      }
    });
};

const BikePrivate = () => {
    const {
      translations, bikePrivate, configuration
    } = useSelector(state => ({
      translations: state.translations,
      bikePrivate: state.bikePrivate,
      configuration: state.configuration,
    }), shallowEqual);

  const dispatch = useDispatch();

  return (
        <div className="sc-grid-row">
            <picture className="lc-bike-private-header-img">
              <source
                type="image/webp"
                srcSet={`${mobileHeroImagePath}.webp 700w, ${desktopHeroImagePath}.webp 1100w`}
                sizes="(min-width: 768px) 1100px, 100vw"
              />

              <img
                src={`${mobileHeroImagePath}.jpg`}
                srcSet={`${mobileHeroImagePath}.jpg 700w, ${desktopHeroImagePath}.jpg 1100w`}
                sizes="(min-width: 768px) 1100px, 100vw"
                loading="eager"
                decoding="async"
                alt="Bike"
              />
            </picture>
            <h1 className="lc-header-bike-private sc-font-bold sc-font-xxl lc-margin-top-m">{translations.bikePrivate.data.headline}</h1>
            <div className="lc-white-box lc-block-center lc-padding-horizontal-l lc-padding-bottom-xl sc-grid-col-5 sc-grid-col-s-12">
                <h2 className="lc-header sc-font-bold sc-font-m lc-padding-top-l lc-padding-bottom-l">{translations.bikePrivate.data.description}</h2>
                <Make styles="sc-grid-row sc-grid-col-11 lc-block-center max-width-s" modelsUrl={configuration.urls.modelsUrl}>
                  {topMakeModel(bikePrivate)}
                </Make>
                <Model styles="sc-grid-row sc-grid-col-11 lc-block-center max-width-s" />
                <Button
                  id="submit-bike-private"
                  className="sc-btn-bob sc-btn-block sc-grid-row sc-grid-col-6 sc-grid-col-s-11 max-width-s lc-block-center lc-margin-top-xl"
                  text={translations.bikePrivate.data.buttonText}
                  onClick={onClick(dispatch, bikePrivate, configuration)}
                />
            </div>
        </div>
);
};

export default BikePrivate;
