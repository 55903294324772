import {
    TOUCH_LICENSE_PLATE_FIELDS,
    CHANGE_LICENSE_PLATE_VALUE,
    CHANGE_IS_LOADING,
    SET_LICENSE_PLATE_TO_RED,
    SET_LICENSE_PLATE_CONFIGURATION,
    CHANGE_IS_RECAPTCHA_VERIFIED,
} from './actionTypes';
import { stringMandatory } from '../../common/defaultValues';

export const initialState = {
    licensePlate: {
        ...stringMandatory,
        validationRegex: /[\s\S]*/,
    },
    isFetchingVehicles: false,
    isLoading: false,
    isRecaptchaVerified: false
};

const validateLicensePlate = (value, validationRegex) => {
    const valueSafeToUpperCase = value ? value.toUpperCase() : value;

    return {
        value: valueSafeToUpperCase,
        isPristine: false,
        isValid: validationRegex.test(valueSafeToUpperCase),
    };
};

const licensePlate = (state = initialState, action) => {
    switch (action.type) {
        case TOUCH_LICENSE_PLATE_FIELDS:
            return { ...state, licensePlate: { ...state.licensePlate, isPristine: false } };
        case CHANGE_LICENSE_PLATE_VALUE:
            return {
                ...state,
                licensePlate: {
                    ...state.licensePlate,
                    isHighlighted: false,
                    ...validateLicensePlate(action.value, state.licensePlate.validationRegex),
                }
            };
        case CHANGE_IS_LOADING:
            return { ...state, isLoading: !state.isLoading };
        case SET_LICENSE_PLATE_TO_RED:
            return {
                ...state,
                licensePlate: {
                    ...state.licensePlate,
                    isHighlighted: true,
                }
            };
        case SET_LICENSE_PLATE_CONFIGURATION:
            return {
                ...state,
                licensePlate: {
                    ...state.licensePlate,
                    validationRegex: action.value
                }
            };
        case CHANGE_IS_RECAPTCHA_VERIFIED:
            return { ...state, isRecaptchaVerified: !state.isRecaptchaVerified };
        default:
            return state;
    }
};

export default licensePlate;
