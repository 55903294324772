import { connect } from 'react-redux';
import InputGroup from '../../../common/components/inputGroup';
import {
  changeBuildRange,
  changeFirstRegistrationAndUpdateLowerFields,
  fillManualLink
} from '../actions';
import { fieldNames } from '../../../common/constants';

function mapStateToProps(state) {
  const pageName = state.configuration.translationsPageName.makemodel.pageName;
  return {
    buildRange: state.makeModelData.buildRange,
    buildRangeText: state.translations[pageName].data.buildRange,
    firstRegistrationText: state.translations[pageName].data.firstRegistration,
    tooltipContent: () => state.translations[pageName].data.buildYearTooltip,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: (val) => {
      dispatch(changeBuildRange(val));
      dispatch(changeFirstRegistrationAndUpdateLowerFields('month', 'year', 0, fieldNames.firstRegistration));
      dispatch(changeFirstRegistrationAndUpdateLowerFields('year', 'month', 0, fieldNames.firstRegistration));
      dispatch(fillManualLink());
    }
  };
}
const BuildDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(InputGroup);

export default BuildDate;
