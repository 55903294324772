import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ButtonLicensePlate from './elements/buttonLicensePlate';
import InputLicensePlate from './elements/inputLicensePlate';
import LoadingSpinner from '../../common/components/loadingSpinner';
import { changeIsReCaptchaVerified } from './action';

const LicensePlate = () => {
  const { translations, isLoading, showCarPrivateBgTabs } = useSelector(state => ({
      translations: state.translations.licensePlate.data,
      isLoading: state.licensePlate.isLoading,
      showCarPrivateBgTabs: state.configuration.toggles.showCarPrivateBgTabs
  }), shallowEqual);
    const dispatch = useDispatch();
    const isTransparent = isLoading ? 'lc-transparent' : '';
  const showFooter = !!translations.finePrint || !!translations.dataSource;

  const divFormClass = `sc-clearfix sc-block-center sc-padding-bottom-xxl sc-grid-col-s-12 sc-grid-row ${(showCarPrivateBgTabs
    ? 'lc-padding-horizontal-xxxl sc-grid-col-12'
    : 'sc-padding-horizontal-l sc-grid-col-6')}`;

  return (
    <div className="sc-grid-row">
      <div className="sc-grid-col-3 sc-hidden-s" />
      <div className={divFormClass}>
        <div className="license-plate-form-header">
          <p className="sc-font-bold sc-absolute-center sc-margin-top-xxl">{translations.instructions}</p>
          <p className="sc-font-silent sc-text-center sc-absolute-center sc-font-s sc-grid-col-7 sc-grid-col-m-11 max-width-s lc-block-center sc-padding-bottom-xxl sc-margin-bottom-xxl">
            {translations.instructionsSubtitle}
          </p>
        </div>
        <form className="license-plate-form">
          <InputLicensePlate />
            <div className="license-plate-button sc-btn-block sc-grid-row sc-grid-col-7 sc-grid-col-m-11 max-width-s lc-block-center sc-margin-top-xxl">
              <ReCAPTCHA
                sitekey="6LfKST8bAAAAAIXcGvqWDrDGZvvNB46rdox9aYIZ"
                onChange={() => dispatch(changeIsReCaptchaVerified())}
              />
            </div>
          <ButtonLicensePlate />
        </form>
          <div className="form-footer lc-centered-flex">
              <LoadingSpinner className="lc-position-absolute" isLoading={isLoading} message={translations.waitMessage} />
              { showFooter && (
                  <p className={'sc-font-silent sc-font-s sc-grid-row sc-grid-col-7 sc-grid-col-m-11 max-width-s'
              + `lc-block-center sc-margin-top-xxxl sc-margin-bottom-xxl lc-position-relative ${isTransparent}`}
                  >
                  {translations.finePrint}
                  <br />
                  {translations.dataSource}
                  </p>
              )}
          </div>
      </div>
    </div>
  );
};

export default LicensePlate;
