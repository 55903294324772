import { connect } from 'react-redux';
import Vin from './vinComponent';
import getVehiclesAsync from './vinApi';
import { updateVin } from './vinActions';

function mapStateToProps(state) {
  return {
    vinData: state.vin.vinData,
    errorOccurred: state.vin.errorOccurred,
    success: state.vin.success,
    isLoading: state.vin.isLoading,
    translations: state.translations,
    listingFormUrl: state.configuration.urls.formUrl
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: vin => dispatch(getVehiclesAsync(vin)),
    onVinChange: vin => dispatch(updateVin(vin))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Vin);
