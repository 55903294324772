import { NOTIFICATION_SHOW } from './actionTypes';

export const initialState = {
    notifications: [],
};

const notification = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_SHOW:
            return {
                ...state,
                notifications: [...state.notifications, action.notification]
            };
        default:
            return state;
    }
};

export default notification;
