import { connect } from 'react-redux';
import {
  changeAndUpdateLowerFields, getNextData, clearResults, fillManualLink, buttonReset
} from '../actions';
import DropDown from '../../../common/components/dropdown';
import { fieldNames } from '../../../common/constants';
import { stringUnderscoreSeparated } from '../../../common/defaultValues';

function mapStateToProps(state) {
  const pageName = state.configuration.translationsPageName.makemodel.pageName;
  return {
    label: state.translations[pageName].data.modelLabel,
    name: fieldNames.model,
    componentState: state.makeModelData.model,
    defaultText: state.translations[pageName].data.pleaseChoose,
    options: state.makeModelData.model.options,
    defaultValue: stringUnderscoreSeparated
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange: (e) => {
      dispatch(changeAndUpdateLowerFields(e.target.value, fieldNames.model));
      if (e.target.value !== '0_0_0') {
        dispatch(getNextData(fieldNames.model));
        dispatch(fillManualLink());
      }
      dispatch(buttonReset());
      dispatch(clearResults());
    }
  };
}
const Model = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDown);

export default Model;
