import { connect } from 'react-redux';
import MakeModelData from './component';

function mapStateToProps(state) {
  return {
    makeModelData: state.makeModelData,
    translations: state.translations,
    showBuildDate: state.configuration.toggles.showBuildDate,
    getPrivateCarTranslations: state.configuration.toggles.getPrivateCarTranslations,
    showCarPrivateBgTabs: state.configuration.toggles.showCarPrivateBgTabs
  };
}

export default connect(
  mapStateToProps
)(MakeModelData);
