import { connect } from 'react-redux';
import { changeNatCode, clearResults, enableFirstReg } from '../actions';
import Input from '../../../common/components/input';

function mapStateToProps(state) {
  return {
    name: 'natCode',
    value: state.natCode.natCode.value,
    label: state.translations.natCode.data.nationalCodeLabel,
    type: 'tel',
    placeholder: state.translations.natCode.data.placeholder,
    minLength: 4,
    maxLength: 6,
    isValid: state.natCode.natCode.isValid,
    isPristine: state.natCode.natCode.isPristine,
    errorMessage: state.translations.natCode.data.errorMessage,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onChange: (e) => {
      dispatch(changeNatCode(e.target.value));
      dispatch(enableFirstReg());
      dispatch(clearResults());
    }
  };
}
const NatCodeInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(Input);

export default NatCodeInput;
