import { connect } from 'react-redux';
import PageHeader from './pageHeaderComponent';


function mapStateToProps(state) {
  return {
    translations: state.translations,
    showDealerHeader: state.configuration.toggles.showDealerHeader,
  };
}

export default connect(mapStateToProps)(PageHeader);
