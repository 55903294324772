function findElementByKeyValue(arraytosearch, key, valuetosearch) {
  let i = 0;
  for (i; i < arraytosearch.length; i++) {
    if (arraytosearch[i][key] === valuetosearch) {
      return arraytosearch[i];
    }
  }
  return null;
}

export function featureEnabled(name) {
  if (typeof window === 'undefined' || typeof window.features === 'undefined') {
    return false;
  }
  const feature = findElementByKeyValue(window.features, 'name', name);
  return feature.enabled;
}
