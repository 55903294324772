import { connect } from 'react-redux';
import { notificationTimeout, notificationType } from './constants';
import NotificationItem from './notificationItem';

function mapStateToProps(state, ownProps) {
    return {
        title: ownProps.title,
        text: ownProps.text,
        uid: ownProps.uid,
        type: notificationType.error,
        timeout: notificationTimeout
    };
}

const GlobalTechnicalError = connect(mapStateToProps)(NotificationItem);

export default GlobalTechnicalError;
