import routes from './routes';
import { getTranslationsAsync } from '../../translations/actions';
import { translationParams } from '../../translations/constants';
import { getMakesAsync, getColorsAsync } from '../../modules/makemodel/actions';
import * as hsnTsnActions from '../../modules/hsntsn/actions';
import { routeEventTrack } from '../../tracking/tracking';
import { lastTabKey } from '../../common/constants';

export const trackEventsOnAppLoad = () => {
  const pathname = window && window.location && window.location.hash ? window.location.hash.slice(1) : routes.makemodel;
  routeEventTrack('myarea_listing_creation', 'default', pathname);
};

const dispatchHeaders = (store, state) => {
  store.dispatch(getTranslationsAsync(translationParams.pageheader));

  const pageName = state.configuration.translationsPageName.vehicle.pageName;
  store.dispatch(getTranslationsAsync(translationParams[pageName]));

  if (state.configuration.toggles.getPrivateCarTranslations) {
    store.dispatch(getTranslationsAsync(translationParams.carPrivate));
  }
};

// TODO: how we can write this rules better
const dispatchMakeModelBaseOnConfiguration = (store, state) => {
  if (state.configuration.toggles.getColors) {
    store.dispatch(getColorsAsync(state.makeModelData.colors));
  }

  if (state.configuration.toggles.getPrivateBikeTranslations) {
    store.dispatch(getTranslationsAsync(translationParams.bikePrivate));
  } else {
    const pageName = state.configuration.translationsPageName.makemodel.pageName;
    store.dispatch(getTranslationsAsync(translationParams[pageName]));
  }
};

const dispatchTranslations = (store, translations) => {
  store.dispatch(getTranslationsAsync(translationParams.countries));

  if (translations.common.data.length === 0 || !translations.common.isFetching) {
    store.dispatch(getTranslationsAsync(translationParams.common));
  }
};

export const routeLocationChange = (store, history) => {
  history.listen((location) => {
    const state = store.getState();
    store.dispatch({ type: 'CLEAR_ALL_DATA' });
    dispatchTranslations(store, state.translations);

    const pathname = state.configuration.tabLocationRule(location);
    if (state.configuration.toggles.shouldUseLocalStorage) {
      localStorage.setItem(lastTabKey, pathname);
    }

    switch (pathname) {
      case routes.vin:
        dispatchHeaders(store, state);
        store.dispatch(getTranslationsAsync(translationParams.vinform));
        break;

      case routes.home:
        dispatchHeaders(store, state);
        dispatchMakeModelBaseOnConfiguration(store, state);
        store.dispatch(getMakesAsync(state.makeModelData.makes, state.configuration.urls.makeUrl));
        break;

      case routes.makemodel:
        dispatchHeaders(store, state);
        dispatchMakeModelBaseOnConfiguration(store, state);
        store.dispatch(getMakesAsync(state.makeModelData.makes, state.configuration.urls.makeUrl));
        store.dispatch(getColorsAsync(state.makeModelData.colors));
        store.dispatch(getTranslationsAsync(translationParams.makemodel));
        break;

      case routes.hsntsn:
        dispatchHeaders(store, state);
        store.dispatch(hsnTsnActions.getColorsAsync(state.hsnTsnData.colors));
        store.dispatch(getTranslationsAsync(translationParams.hsntsn));
        break;

      case routes.natcode:
        dispatchHeaders(store, state);
        store.dispatch(getTranslationsAsync(translationParams.natCode));
        break;

      case routes.licensePlate:
        dispatchHeaders(store, state);
        store.dispatch(getTranslationsAsync(translationParams.licensePlate));
        break;

      default:
        break;
    }
  });
};
