import { getVehicleData } from '@autoscout24/as24-vehicle-data-local';
import * as actionTypes from './actionTypes';
import * as defaultValues from '../../common/defaultValues';
import { index, fields, vehicleOfferTypeDefaultValue } from '../../common/constants';
import { firstRegistrationMonthAndYear, firstRegistrationValidation, firstRegistrationClear } from '../../common/validation';


const prefillVehicle = getVehicleData();
const canBePrefilled = prefillVehicle && prefillVehicle.makeId && prefillVehicle.firstRegistrationYear && prefillVehicle.firstRegistrationMonth
    && prefillVehicle.modelId && prefillVehicle.bodyId && prefillVehicle.doorCount && prefillVehicle.fuelId;
const modelDataMLC = `${prefillVehicle.modelId}_${prefillVehicle.bodyId}_${prefillVehicle.doorCount}`;
const monthMLC = prefillVehicle?.firstRegistrationMonth?.padStart(2, '0');


export const initialState = {
  buildRange: false,
  firstRegistration: canBePrefilled ? {
    month: monthMLC,
    year: prefillVehicle.firstRegistrationYear,
    isValid: true,
    isPristine: false,
    monthPristine: false,
    yearPristine: false,
    isDisabled: false
  } : defaultValues.firstReg,
  make: canBePrefilled ? {
    value: prefillVehicle.makeId,
    isHidden: false,
    isValid: false
  } : defaultValues.selectNumber,
  makes: [],
  topMakes: [],
  fuel: canBePrefilled ? {
    value: prefillVehicle.fuelId,
    options: [{ id: prefillVehicle.fuelId, label: prefillVehicle.fuelCategory }],
    isValid: true,
    isHidden: false
  } : defaultValues.selectStringDisabled,
  model: canBePrefilled ? {
        value: modelDataMLC,
        options: [{ id: modelDataMLC, label: `${prefillVehicle.model} ${prefillVehicle.bodyTypeAndDoors}` }],
        isValid: true,
        isDisabled: false
      } : defaultValues.selectStringUnderscoreSeparated,
  colors: [],
  color: defaultValues.selectStringDisabled,
  isFetchingColor: false,
  power: defaultValues.selectNumberHidden,
  equipmentLine: defaultValues.selectStringHidden,
  isFetchingMakes: false,
  redirectButton: {
    show: false,
  },
  vehicleOfferType: {
    value: vehicleOfferTypeDefaultValue,
    isValid: true,
    isDisabled: false,
  },
  isFetchingVehicleOfferTypes: false,
  vehicleOfferTypes: [],
  firstRegistrationMonthAndYear: {
    month: '0',
    year: '0',
    isValid: false,
    isPristine: true,
    monthPristine: true,
    yearPristine: true,
    limitIsOk: true,
    isDisabled: true
  },
  firstRegistrationValidation: {
    vehicleType: '',
    minYear: 100,
    maxYear: 0,
    mandatory: true,
    visible: true,
  },
};

const setDefault = (obj, idx) => {
  if (idx === fields.length) {
    return obj;
  }
  return setDefault({ ...obj, [fields[idx].name]: fields[idx].value }, idx + 1);
};

const updateFields = (name, value, state) => {
  return setDefault({ ...state, [name]: { ...state[name], value, isValid: value !== fields[index[name]].value.value } }, index[name] + 1);
};

const checkIfFirstRegistrationIsValid = (state, action) => action.value !== '0'
    && state.firstRegistration[action.otherField] !== '0'
    && action.value !== 0
    && state.firstRegistration[action.otherField] !== 0;

const updateFirstRegAndLowerFields = (state, action) => {
  const newState = {
    ...state,
    firstRegistration: {
      ...state.firstRegistration,
      [action.field]: action.value,
      [`${action.field}Pristine`]: false,
      isPristine: state.firstRegistration[`${action.otherField}Pristine`],
      isValid: checkIfFirstRegistrationIsValid(state, action)
    }
  };
  return setDefault(newState, index[action.name] + 1);
};
const updateLowerFields = (state, action) => {
  const newState = {
    ...state,
    [action.field]: {
      ...state[action.field],
      options: action.data,
      value: (action.data.length === 1) ? action.data[0].id : fields[index[action.field]].value.value,
      isValid: action.data.length === 1,
      isHidden: false,
      isDisabled: action.data.length === 1
    }
  };
  return setDefault(newState, index[action.field] + 1);
};

const makeModelData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_AND_UPDATE_LOWER_FIELDS:
      return Object.assign({}, state, updateFields(action.name, action.value, state));
    case actionTypes.MAKES_REQUEST:
      return { ...state, isFetchingMakes: true };
    case actionTypes.MAKES_SUCCESS:
      return {
        ...state,
        topMakes: action.topMakes,
        makes: action.makes,
        isFetchingMakes: false
      };
    case actionTypes.MAKES_FAILURE:
      return state;
    case actionTypes.COLORS_REQUEST:
      return { ...state, isFetchingColors: true };
    case actionTypes.COLORS_SUCCESS:
      return {
        ...state,
        colors: action.colors,
        isFetchingColors: false
      };
    case actionTypes.COLORS_FAILURE:
      return state;
    case actionTypes.SHOW_FIRST_REG:
      return {
        ...state,
        firstRegistration: { ...state.firstRegistration, isDisabled: false }
      };
    case actionTypes.SHOW_FIRST_REG_MAKE_MODEL:
      return {
        ...state,
        firstRegistrationMonthAndYear: { ...state.firstRegistrationMonthAndYear, isDisabled: false },
      };
    case actionTypes.CHANGE_FIRST_REG_AND_UPDATE_LOWER_FIELDS:
      return Object.assign({}, updateFirstRegAndLowerFields(state, action));
    case actionTypes.TAXONOMY_SUCCESS:
      return updateLowerFields(state, action);
    case actionTypes.TAXONOMY_FAILURE:
      return prefillVehicle && prefillVehicle.fuelId ? {
        ...state,
        make: defaultValues.selectNumber,
        firstRegistration: defaultValues.firstReg,
        model: defaultValues.selectStringUnderscoreSeparated,
        fuel: defaultValues.selectStringDisabled
      } : state;
    case actionTypes.PREFILL_DELETE:
      return {
        ...state,
        make: {
          value: action.value,
          isHidden: false,
          isValid: false
        },
        firstRegistration: defaultValues.firstReg,
        model: defaultValues.selectStringUnderscoreSeparated,
        fuel: defaultValues.selectStringDisabled,
        power: defaultValues.selectNumberHidden
      };
    case actionTypes.SHOW_COLOR:
      return {
        ...state,
        color: { ...state.color, isDisabled: false }
      };
    case actionTypes.SHOW_EQUIPMENT_LINE:
      return {
        ...state,
        equipmentLine: { ...state.equipmentLine, isHidden: false, isDisabled: true }
      };
    case actionTypes.BUTTON_RESET:
      return {
        ...state,
        redirectButton: {
          ...state.redirectButton,
          show: false,
        }
      };
    case actionTypes.BUTTON_REDIRECT: {
      const showButton = !action.isTaxonomyFlow && state.fuel.value !== '0' && state.color.value !== '0';
      return showButton ? {
        ...state,
        redirectButton: {
          ...state.redirectButton,
          show: true,
        },
        power: { ...state.power, isHidden: true },
        equipmentLine: { ...state.equipmentLine, isHidden: true }
      }
        : {
          ...state,
          redirectButton: {
            ...state.redirectButton,
            show: showButton,
          }
        };
    }
    case actionTypes.VEHICLE_OFFER_TYPES_REQUEST_MAKE_MODEL:
      return { ...state, isFetchingVehicleOfferTypes: true };
    case actionTypes.VEHICLE_OFFER_TYPES_SUCCESS_MAKE_MODEL:
      return {
        ...state,
        vehicleOfferTypes: action.vehicleOfferTypes,
        isFetchingVehicleOfferTypes: false
      };
    case actionTypes.VEHICLE_OFFER_TYPES_FAILURE_MAKE_MODEL:
      return state;
    case actionTypes.CHANGE_VEHICLE_OFFER_TYPE_MAKE_MODEL:
      return {
        ...state,
        vehicleOfferType: {
          ...state.vehicleOfferType,
          value: action.value
        }
      };
    case actionTypes.FIRST_REG_CHANGE_MAKE_MODEL:
      return {
        ...state,
        firstRegistrationMonthAndYear: {
          ...state.firstRegistrationMonthAndYear,
          ...firstRegistrationMonthAndYear(state, action.value, action.otherField),
        },
      };
    case actionTypes.FIRST_REG_CHANGE_VALIDATION_MAKE_MODEL: {
      const tempState = {
        ...state,
        firstRegistrationValidation: firstRegistrationValidation(state, action.value),
      };

      return {
        ...tempState,
        firstRegistrationMonthAndYear: {
          ...tempState.firstRegistrationMonthAndYear,
          ...firstRegistrationClear(),
        },
      };
    }
    case actionTypes.CHANGE_BUILD_RANGE: {
      return {
        ...state,
        buildRange: action.value,
      };
    }
    default:
      return state;
  }
};
export default makeModelData;
