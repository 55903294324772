export function getVehiclesRequest() {
  return {
    type: 'GET_VEHICLES_REQUEST'
  };
}

export function getVehiclesSuccess(vehicles) {
  return {
    type: 'GET_VEHICLES_SUCCESS',
    vehicles
  };
}

export function updateVin(vin) {
  return {
    type: 'UPDATE_VIN',
    vin
  };
}

export function getVehiclesFailure() {
  return {
    type: 'GET_VEHICLES_FAILURE'
  };
}

export function getVehiclesVinError(vinError) {
  return {
    type: 'GET_VEHICLES_VIN_ERROR',
    vinError
  };
}
export const fetchVehicleOfferTypes = () => {
  return {
    type: 'VEHICLE_OFFER_TYPES_REQUEST_VIN'
  };
};
export const vehicleOfferTypesSuccess = (vehicleOfferTypes) => {
  return {
    type: 'VEHICLE_OFFER_TYPES_SUCCESS_VIN',
    vehicleOfferTypes
  };
};
export const vehicleOfferTypesFailure = (error) => {
  return {
    type: 'VEHICLE_OFFER_TYPES_FAILURE_VIN',
    error
  };
};
const getVehicleOfferTypeData = () => fetch('/listing-creation-entry-point/lookups/vehicle-offer-types', { credentials: 'include' })
  .then(response => response.json());
export const fetchVehicleOfferTypesAsync = (getVehicleOfferTypes = getVehicleOfferTypeData) => (dispatch) => {
  dispatch(fetchVehicleOfferTypes());
  return getVehicleOfferTypes()
    .then((json) => {
      dispatch(vehicleOfferTypesSuccess(json.vehicleOfferTypes));
    })
    .catch((error) => {
      dispatch(vehicleOfferTypesFailure(error));
    });
};
export const getVehicleOfferTypesAsync = (vehicleOfferTypes) => {
  if (Object.keys(vehicleOfferTypes).length > 0) {
    return null;
  }
  return fetchVehicleOfferTypesAsync();
};
export const changeVehicleOfferType = (value) => {
  return {
    type: 'CHANGE_VEHICLE_OFFER_TYPE_VIN',
    value
  };
};
export const setVehicleOfferTypeParameter = (value) => {
  return {
    type: 'SET_VEHICLE_OFFER_TYPE_PARAMETER',
    value
  };
};
