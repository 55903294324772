import { connect } from 'react-redux';
import InputGroup from '../../../common/components/inputGroup';
import { changeBuildRange, changeFirstRegistrationAndUpdateLowerFields } from '../actions';
import { fieldNames } from '../../../common/constants';

function mapStateToProps(state) {
  return {
    buildRange: state.hsnTsnData.buildRange,
    buildRangeText: state.translations.hsntsn.data.buildRange,
    firstRegistrationText: state.translations.hsntsn.data.firstRegistration,
    tooltipContent: () => state.translations.hsntsn.data.buildYearTooltip,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClick: (val) => {
      dispatch(changeBuildRange(val));
      dispatch(changeFirstRegistrationAndUpdateLowerFields('month', 'year', 0, fieldNames.firstRegistration));
      dispatch(changeFirstRegistrationAndUpdateLowerFields('year', 'month', 0, fieldNames.firstRegistration));
    }
  };
}
const BuildDate = connect(
  mapStateToProps,
  mapDispatchToProps
)(InputGroup);

export default BuildDate;
