import { connect } from 'react-redux';
import DropDown from '../../../common/components/dropdown';
import { fieldNames } from '../../../common/constants';
import { changeFieldValue, clearForm } from '../actions';
import { fetchModelAsync } from '../../services/lookupApi';

function mapStateToProps(state) {
  return {
    label: state.translations.bikePrivate.data.makeLabel,
    name: fieldNames.make,
    componentState: state.bikePrivate.make,
    defaultText: state.translations.bikePrivate.data.pleaseChoose,
    options: state.makeModelData.makes,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onChange: (e) => {
      const value = e.target.value;
      dispatch(clearForm());
      dispatch(changeFieldValue(fieldNames.make, value));
      dispatch(fetchModelAsync(value, ownProps.modelsUrl));
    }
  };
}
const Make = connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDown);

export default Make;
