import {
  CHANGE_IS_LOADING,
  CHANGE_LICENSE_PLATE_VALUE,
  SET_LICENSE_PLATE_TO_RED,
  TOUCH_LICENSE_PLATE_FIELDS,
  SET_LICENSE_PLATE_CONFIGURATION, CHANGE_IS_RECAPTCHA_VERIFIED,
} from './actionTypes';
import { CLEAR_VEHICLES_RESULT } from '../../app/actionTypes';

export const touchLicensePlateFields = () => ({ type: TOUCH_LICENSE_PLATE_FIELDS });

export const changeLicensePlateValue = (value) => {
  return {
    type: CHANGE_LICENSE_PLATE_VALUE,
    value
  };
};

export const clearResults = () => {
  return {
    type: CLEAR_VEHICLES_RESULT
  };
};

export const changeIsLoading = () => ({ type: CHANGE_IS_LOADING });

export const setLicensePlateToRed = () => ({ type: SET_LICENSE_PLATE_TO_RED });

export const setLicensePlateConfiguration = validationRegex => ({
  type: SET_LICENSE_PLATE_CONFIGURATION,
  value: validationRegex
});

export const changeIsReCaptchaVerified = () => ({ type: CHANGE_IS_RECAPTCHA_VERIFIED });
