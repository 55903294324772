import * as actionTypes from './actionTypes';

const initialState = {
  vinform: { data: [], isFetching: false },
  pageheader: { data: [], isFetching: false },
  vehicle: { data: [], isFetching: false },
  carSourcingVehicle: { data: [], isFetching: false },
  carSourcingMakemodel: { data: [], isFetching: false },
  common: { data: [], isFetching: false },
  makemodel: { data: [], isFetching: false },
  hsntsn: { data: [], isFetching: false },
  natCode: { data: [], isFetching: false },
  countries: { data: [], isFetching: false },
  bikePrivate: { data: [], isFetching: false },
  carPrivate: { data: [], isFetching: false },
  licensePlate: { data: [], isFetching: false }
};

const translations = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRANSLATIONS_REQUEST:
      return { ...state, [action.pageName]: { ...state[action.pageName], isFetching: true } };
    case actionTypes.TRANSLATIONS_SUCCESS:
      return { ...state, [action.pageName]: { data: action.translations, isFetching: false } };
    default:
      return state;
  }
};

export default translations;
